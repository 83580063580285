import {
  BookingsPage,
  BookingListPage,
  CaretakerPage,
  MyAccountPage,
  PerformancePage,
  SignOutPage,
  CookiePolicyPage,
  PricingListPage,
  PriceRecommendationsPage,
  HumanRightsEthicsPage,
  DataProtectionPage,
  NewsPage,
  BenefitsPage,
  GeneralFaqPage,
  HealthAndSafetyFaqPage,
  ContactUsPage,
  PropertyAmendsPage,
  PropertyAmendsDescriptionPage,
  PropertyAmendsArrivalPage,
  PropertyAmendsImageryPage,
  PropertyAmendsFeaturesPage,
  OnlineStatementsPage,
  BookingAllowancePage,
  UserAdminPage,
  UnitChartPage,
  ReportsPage,
  GuestReviewsPage,
  OwnerServiceRedirect,
  ReviewResponsePage
} from '../pages'
import { isEnabled } from '@/constants/features'

export const ROUTES = [
  {
    pageKey: 'BookingsPage',
    permissions: ['Bookings', 'bookings'],
    brand: ['awaze', 'novasol'],
    path: '/bookings/calendar',
    component: BookingsPage,
    exact: true,
    pageInfo: {
      type: 'Bookings',
      subtype: 'Calendar'
    }
  },
  {
    pageKey: 'UnitChartPage',
    path: '/bookings/chart',
    permissions: ['Bookings', 'bookings'],
    brand: ['awaze'],
    component: UnitChartPage,
    exact: true,
    pageInfo: {
      type: 'Unit Chart'
    }
  },
  {
    pageKey: 'Home',
    path: '/bookings/list',
    permissions: ['Home', 'home'],
    brand: ['awaze', 'novasol'],
    component: BookingListPage,
    exact: true,
    pageInfo: {
      type: 'Bookings',
      subtype: 'Booking List'
    }
  },
  {
    pageKey: 'PerformancePage',
    path: '/performance',
    permissions: ['Performance', 'performance'],
    component: PerformancePage,
    exact: true,
    pageInfo: {
      type: 'Performance'
    }
  },
  {
    pageKey: 'MyAccountPage',
    path: '/personal/account-details',
    brand: ['awaze'],
    component: MyAccountPage,
    exact: true,
    pageInfo: {
      type: 'Account',
      subtype: 'Account Details'
    }
  },
  {
    pageKey: 'CaretakersPage',
    path: '/caretakers',
    permissions: ['Caretakers', 'caretakers'],
    brand: ['awaze'],
    component: CaretakerPage,
    exact: true,
    pageInfo: {
      type: 'Caretakers'
    }
  },
  {
    pageKey: 'PricingList',
    path: '/pricing-list',
    permissions: ['Pricing', 'pricing'],
    brand: ['awaze'],
    component: PricingListPage,
    exact: true,
    pageInfo: {
      type: 'Pricing List'
    }
  },
  {
    pageKey: 'NewPriceRecommendations',
    path: '/pricing-list/price-recommendations',
    permissions: ['Pricing', 'pricing'],
    component: PriceRecommendationsPage,
    brand: ['awaze', 'novasol'],
    exact: true,
    pageInfo: {
      type: 'Pricing List'
    }
  },
  {
    pageKey: 'SignOutPage',
    path: '/signout',
    component: SignOutPage,
    exact: true,
    pageInfo: {
      type: 'Sign Out'
    }
  },
  {
    pageKey: 'CookiePolicy',
    path: '/cookie-policy',
    brand: ['awaze'],
    component: CookiePolicyPage,
    exact: true,
    pageInfo: {
      type: 'Company Info',
      subtype: 'Cookie Policy'
    }
  },
  {
    pageKey: 'GuestReviews',
    path: '/property/reviews',
    permissions: ['Property', 'property'],
    brand: ['awaze', 'novasol'],
    component: GuestReviewsPage,
    exact: true,
    pageInfo: {
      type: 'Guest Feedback'
    }
  },
  {
    pageKey: 'HumanRightsEthics',
    path: '/social-responsibility',
    component: HumanRightsEthicsPage,
    exact: true,
    pageInfo: {
      type: 'Company Info',
      subtype: 'Human Rights and Ethics'
    }
  },
  {
    pageKey: 'DataProtection',
    path: '/data-protection',
    component: DataProtectionPage,
    exact: true,
    pageInfo: {
      type: 'Company Info',
      subtype: 'Data Protection'
    }
  },
  ...(isEnabled('owner-services-mfe') ? [
    {
      path: '/news/*',
      pageKey: 'News Page',
      brand: ['awaze', 'novasol'],
      component: OwnerServiceRedirect
    },
    {
      path: '/articles/*',
      pageKey: 'Articles Page',
      brand: ['awaze', 'novasol'],
      component: OwnerServiceRedirect
    }
  ] : [
    {
      pageKey: 'OwnerNews',
      path: '/news',
      permissions: ['News', 'news'],
      brand: ['awaze'],
      component: NewsPage,
      exact: true,
      pageInfo: {
        type: 'Owner News'
      }
    }
  ]),
  {
    pageKey: 'Benefits',
    path: '/benefits',
    brand: ['awaze'],
    permissions: ['Benefits', 'benefits'],
    component: isEnabled('owner-services-mfe') ? OwnerServiceRedirect : BenefitsPage,
    exact: true,
    pageInfo: {
      type: 'Benefits'
    }
  },
  {
    pageKey: 'GeneralFaqs',
    path: '/general-faq',
    brand: ['awaze'],
    component: GeneralFaqPage,
    exact: true,
    pageInfo: {
      type: 'FAQs',
      subtype: 'General FAQs'
    }
  },
  {
    pageKey: 'HealthAndSafetyFaqs',
    path: '/health-and-safety',
    component: HealthAndSafetyFaqPage,
    exact: true,
    pageInfo: {
      type: 'FAQs',
      subtype: 'Health and Safety FAQs'
    }
  },
  {
    pageKey: 'ContactUs',
    path: '/contact-us',
    component: ContactUsPage,
    permissions: ['Need Help', 'help', 'needHelp'],
    exact: true,
    pageInfo: {
      type: 'Contact Us'
    }
  },
  {
    pageKey: 'OnlineStatements',
    path: '/financial/statements',
    permissions: ['Financial', 'financial'],
    brand: ['awaze'],
    component: OnlineStatementsPage,
    exact: true,
    pageInfo: {
      type: 'Financial',
      subtype: 'Online Statements'
    }
  },
  {
    pageKey: 'CurrentPropertyAmendsPage',
    path: '/property/amends',
    permissions: ['Property', 'property'],
    brand: ['awaze'],
    component: PropertyAmendsPage,
    exact: true,
    pageInfo: {
      type: 'Property Amends'
    }
  },
  {
    pageKey: 'BookingAllowancePage',
    path: '/financial/booking-allowance',
    permissions: ['Financial', 'financial'],
    brand: ['awaze'],
    component: BookingAllowancePage,
    exact: true,
    pageInfo: {
      type: 'Financial',
      subtype: 'Booking Allowance'
    }
  },
  {
    path: '/property-amends/:id?',
    pageKey: 'Property Amends Page',
    permissions: ['Property', 'property'],
    brand: ['awaze'],
    component: PropertyAmendsPage,
    exact: true,
    pageInfo: {
      type: 'Property Amends',
      subtype: 'Property'
    }
  },
  {
    path: '/property-amends/description/:name/:id',
    pageKey: 'Property Amends Description Page',
    permissions: ['Property', 'property'],
    brand: ['awaze'],
    component: PropertyAmendsDescriptionPage,
    exact: true,
    pageInfo: {
      type: 'Property Amends',
      subtype: 'Description'
    }
  },
  {
    path: '/property-amends/arrival/:name/:id',
    pageKey: 'Property Amends Arrival Page',
    permissions: ['Property', 'property'],
    brand: ['awaze'],
    component: PropertyAmendsArrivalPage,
    exact: true,
    pageInfo: {
      type: 'Property Amends',
      subtype: 'Arrivals'
    }
  },
  {
    path: '/property-amends/images/:name/:id',
    pageKey: 'Property Amends Imagery Page',
    permissions: ['Property', 'property'],
    brand: ['awaze'],
    component: PropertyAmendsImageryPage,
    exact: true,
    pageInfo: {
      type: 'Property Amends',
      subtype: 'Imagery'
    }
  },
  {
    path: '/property-amends/features/:name/:id',
    pageKey: 'Property Amends Features Page',
    permissions: ['Property', 'property'],
    brand: ['awaze'],
    component: PropertyAmendsFeaturesPage,
    exact: true,
    pageInfo: {
      type: 'Property Amends',
      subtype: 'Features'
    }
  },
  {
    path: '/user-admin',
    pageKey: 'User Admin Page',
    permissions: ['Admin', 'admin'],
    brand: ['awaze'],
    component: UserAdminPage,
    exact: true,
    pageInfo: {
      type: 'User Admin'
    }
  },
  {
    path: '/reports',
    pageKey: 'Reports Page',
    permissions: ['viewSiteReports', 'ViewSiteReports'],
    brand: ['awaze'],
    component: ReportsPage,
    exact: true,
    pageInfo: {
      type: 'Reports'
    }
  },
  {
    pageKey: '/ReviewResponsePage',
    path: '/property/reviews/:propertyId/:reviewId',
    permissions: ['Property', 'property'],
    brand: ['awaze', 'novasol'],
    component: ReviewResponsePage,
    exact: true,
    pageInfo: {
      type: 'Owner Feedback'
    }
  }
]
