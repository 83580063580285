import dayjs from 'dayjs'
import React, { useRef, useState, useEffect } from 'react'
import {
  BOOKING_TYPE_CANCELLED,
  CONSOLIDATED_BOOKING_STATE_CANCELLED,
  CONSOLIDATED_BOOKING_STATE_CUSTOMER
} from '@/constants/bookings'
import useDeepEffect from '@/hooks/useDeepEffect'
import { createICSEventFromBooking } from '@/helpers/files/ICS/ICS'
import BookingsPDF from '@/helpers/files/PDF/components/BookingsPDF'
import BookingListDateFilter from '@/components/__BookingComponents/BookingListDateFilter'
import {
  StyledSelect,
  StyledExport,
  FilterWrapper,
  ButtonGroup,
  BookingTypeControl,
  SelectFilterWrapper
} from './BookingListControls.styles'
import { useBookingListContext } from '@/context/BookingListProvider'
import BookingListDropdownWrapper from '@/components/__BookingComponents/BookingListDropdownWrapper'
import { BookingListFilterMenu } from '@/components/__BookingComponents'
import { FilterIconButton } from '@/components/__UI'
import useWindowSize from '@/hooks/useWindowSize'
import { useTranslation } from 'react-i18next'

const BookingListControls = () => {
  const { t } = useTranslation()

  const { width } = useWindowSize()
  const isIpad = width >= 600 && width < 1025
  const isMobile = width < 600

  const {
    filteredProperty,
    setFilteredProperty,
    filteredBookingType,
    setFilteredBookingType,
    filteredBookings,
    setFilteredBookings,
    isLoadingBookings,
    startDate,
    endDate,
    allBookings,
    allSitesWithProperties,
    filteredSite,
    setFilteredSite,
    setAllBookings,
    searchBy,
    hasOnlyOneSite,
    hasOnlyOneAccommodation,
    isErrorBookings,
    hideUnitDisplay
  } = useBookingListContext()

  const [isFilterWrapperVisible, setFilterWrapperVisible] = useState(false)
  const [isFilterButtonVisible, setIsFilterButtonVisislbe] = useState(isIpad)
  const [buttonText, setButtonText] = useState(t('filters'))

  useEffect(() => {
    if (width < 1025) {
      setIsFilterButtonVisislbe(true)
    } else {
      setIsFilterButtonVisislbe(false)
      setFilterWrapperVisible(true)
    }
  }, [width])

  const handleFilterButtonClick = () => {
    if (isMobile) {
      if (isFilterWrapperVisible) {
        setIsFilterButtonVisislbe(true)
      } else {
        setIsFilterButtonVisislbe(false)
      }
    }
    setFilterWrapperVisible(!isFilterWrapperVisible)
    setButtonText(isFilterWrapperVisible ? t('filters') : t('closeFilters'))
  }

  const includedItems =
    filteredProperty.propertyId === 'all'
      ? 'All'
      : filteredProperty.propertyName

  const exportFilename = `${includedItems} - ${dayjs(startDate).format(
    'MMYY'
  )}-${dayjs(endDate).format('MMYY')} - ${dayjs().format(
    'DDMMYY'
  )} - ${dayjs().format('YYYYMMDD')}`

  // Assures that list is filtered correctly after date picker change
  useDeepEffect(() => {
    if (!isLoadingBookings) {
      filterProperties()
    }
  }, [allBookings, filteredProperty, isLoadingBookings, startDate, endDate])

  const showDatePicker = () => {
    if (searchBy === 'active') {
      return false
    }

    return true
  }

  const handlePropertyChange = (property) => {
    setFilteredProperty(property)
    filterProperties(property)
  }

  const handleSiteChange = (site) => {
    if (site !== undefined && filteredSite.siteId !== site.siteId) {
      setFilteredSite(site)
      setFilteredProperty({
        propertyName: t('allAccommodations'),
        propertyId: 'all'
      })
      setAllBookings([])
      setFilteredBookings([])
    }
  }

  const handleBookingTypeChange = (bookingType) => {
    setFilteredBookingType(bookingType)
    filterProperties(null, bookingType)
  }

  const filterProperties = (property, bookingType) => {
    let filteredList = allBookings || []

    const filterProperty = property || filteredProperty
    const filterBookingType = bookingType || filteredBookingType

    if (filterProperty.propertyId !== 'all') {
      filteredList = filteredList.filter(
        ({ propertyId }) => propertyId === filterProperty.propertyId
      )
    }

    if (filterBookingType.value !== 'all') {
      filteredList = filteredList.filter((booking) => {
        return filterBookingType.value === booking.state
      })
    }

    setFilteredBookings(filteredList)
  }

  const refOptions = useRef([])

  const getBookingTypeOptions = () => {
    const options = [
      {
        label: t('customerBookings'),
        value: CONSOLIDATED_BOOKING_STATE_CUSTOMER
      }
    ]

    if (filteredBookings) {
      if (filteredBookingType.value !== 'all') {
        return refOptions.current
      } else {
        const bookingTypes = [
          ...new Set(filteredBookings.map((item) => item.state))
        ].filter((x) => {
          return x !== CONSOLIDATED_BOOKING_STATE_CUSTOMER
        })

        bookingTypes.forEach((state) => {
          options.push({
            label: t(state.charAt(0).toLowerCase() + state.slice(1)),
            value: state
          })
        })

        if (options.length > 1) {
          options.unshift({ label: t('allBookings'), value: 'all' })
        }

        refOptions.current = options
        return options
      }
    }
    return options
  }

  const isNovasol = !hideUnitDisplay

  if (isErrorBookings) {
    return <></>
  }

  return (
    <>
      {allSitesWithProperties && <SelectFilterWrapper>
        <BookingListDropdownWrapper
          hasOnlyOneSite={hasOnlyOneSite}
          hasOnlyOneAccommodation={hasOnlyOneAccommodation}
          allSitesWithProperties={allSitesWithProperties}
          onPropertyChange={handlePropertyChange}
          selectedPropertyValue={filteredProperty}
          isLoadingBookings={isLoadingBookings}
          selectedSiteValue={filteredSite}
          onSiteChange={handleSiteChange}
        />

        {((isFilterButtonVisible && isIpad) || (!isFilterWrapperVisible && isMobile) || false) && (
          <FilterIconButton
            onClick={handleFilterButtonClick}
            buttonText={buttonText}
          />
        )}
      </SelectFilterWrapper>}
      {isFilterWrapperVisible && (
        <FilterWrapper>
          <BookingListFilterMenu />

          {showDatePicker() ? <BookingListDateFilter /> : <></>}

          <BookingTypeControl>
            <StyledSelect
              name='bookingSelect'
              label={t('bookingType')}
              value={filteredBookingType}
              options={getBookingTypeOptions()}
              onChange={handleBookingTypeChange}
            />
          </BookingTypeControl>
          {isMobile && !isIpad && (
            <div
              style={{ marginBottom: '1rem', maxWidth: '100%', width: '100%' }}
            >
              <FilterIconButton
                onClick={handleFilterButtonClick}
                buttonText={buttonText}
              />
            </div>
          )}
        </FilterWrapper>
      )}

      {!isLoadingBookings && filteredBookings.length > 0 && (
        <ButtonGroup>
          <StyledExport
            data={filteredBookings}
            filename={exportFilename}
            siteUser={allSitesWithProperties.siteUser}
            isNovasol={isNovasol}
            options={{
              ics: {
                events: filteredBookings
                  .filter(
                    (item) => item.state !== CONSOLIDATED_BOOKING_STATE_CANCELLED
                  )
                  .map((booking) =>
                    createICSEventFromBooking(
                      booking,
                      allSitesWithProperties.siteUser,
                      t,
                      isNovasol
                    )
                  ),
                disabled: filteredBookingType.value === BOOKING_TYPE_CANCELLED
              },
              pdf: {
                component: () => (
                  <BookingsPDF
                    title={exportFilename}
                    bookings={filteredBookings}
                    siteUser={allSitesWithProperties.siteUser}
                    isNovasol={isNovasol}
                  />
                )
              }
            }}
          />
        </ButtonGroup>
      )}
    </>
  )
}

export default BookingListControls
