import styled from 'styled-components'
import ExportOptions from '@/components/ExportOptions'
import { PropertyDropdown, SelectDropdown } from '@/components/__Inputs'

const StyledSelect = styled(SelectDropdown)`
.react_select__menu {
    max-height: 7.5rem;
}

.react_select__menu-list {
    max-height: 7.5rem;
    overflow-y: 'auto';
    }
`
const StyledPropertyDropdown = styled(PropertyDropdown)``

const StyledExport = styled(ExportOptions)`
    top: 1rem;
    right: 0;
    background-color: ${({ theme }) => theme.colors.bgColor} !important;
    border-color: ${({ theme }) => theme.colors.bgColor} !important;
    box-shadow: none !important;
    font-size: 1rem;
    height: 1.5rem !important;
    line-height: 1.5rem !important;

    @media screen and (max-width: 1024px) {
        right: 1rem;
    }

    .react_select__control{
        height: auto;
        width: auto;
    }
    .react_select__dropdown-indicator {
        color: ${({ theme }) => theme.colors.link} !important;
        font-weight: 500 !important;
        text-decoration: underline;
    }
    .react_select__placeholder {
        color: ${({ theme }) => theme.colors.link} !important;
        margin-left: -0.5rem;
        text-decoration: underline;
        font-family: 'DM sans', sans-serif;
    }
    .react_select__menu {
        box-shadow: 0px 8px 16px 0px ${({ theme }) => theme.colors.dropDownShadow};
        border: none;
    }
    .react_select__option:hover {
        color: ${({ theme }) => theme.colors.blackTwo};
        background: ${({ theme }) => theme.colors.backgroundHover};
    }
    .react_select__indicator {
        display: none;
    }

    ${({ theme }) => `${theme.mediaQueries.tablet} {
        height: 4rem !important;
        margin-top: 0;
        margin-bottom: 0;
        `}
    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        height: 2rem !important;
        margin-top: 0;
        margin-bottom: 0;
    }`}
    @media (min-width: 600px) and (max-width: 900px) {
        .react_select__menu {
            top: 2.6rem;
        }
    }
`
const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    height: 1rem;
`
const FilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.mediumLargeScreen} {
        background: ${({ theme }) => theme.colors.quoteBackground};
        padding: 1rem;
        border-radius: 0.5rem;
        justify-content: space-between;
        display: grid;
        grid-template-columns: 1fr 1fr;  }

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
        background: ${({ theme }) => theme.colors.quoteBackground};
        padding: 0 1rem;
        border-radius: 0.5rem; 
        grid-template-columns: 1fr;
    }
    
`

const SelectFilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;

    @media only screen and (max-width: 659px) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
`
const DateAndTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
`

const BookingTypeControl = styled.div`
    width: 100%;
    max-width: 15rem;

    ${({ theme }) => theme.mediaQueries.mediumLargeScreen} {
    max-width: unset;
  }

`

const MoreFiltersBtn = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem;

        .filtersBtn{
            width: 9rem;
            height: 3rem;
            border-radius: 4rem;
            border: 2px solid ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};
            font-size: inherit;
            font-weight: inherit;
            font-family: inherit;
            background: transparent;
            outline: none;
        }
`

export { SelectFilterWrapper, StyledSelect, StyledPropertyDropdown, StyledExport, DateAndTypeContainer, FilterWrapper, ButtonGroup, BookingTypeControl, MoreFiltersBtn }
