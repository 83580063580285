import { useTranslation } from 'react-i18next'
import { useSelectedPropertyID } from '@/hooks/useSelectedPropertyID'
import { useUser } from '@/context/UserProvider'
import { isEnabled } from '@/constants/features'

export type NavItem = {
  id: string;
  page: string;
  permissions: string;
  brand?: string[];
  productMarkets?: string[];
  excludedProductMarkets?: string[];
} & ({
  url: string;
} | {
  links: NavSubItem[];
});

export type NavSubItem = {
  id: string;
  label: string;
  permissions: string;
  brand?: string[];
  productMarkets?: string[];
  excludedProductMarkets?: string[];
} & ({
  url: string;
} | {
  href: string;
  target?: string;
});
// Returns all nav items and links
export function useNavigation () {
  const { t } = useTranslation()

  const { propertyID } = useSelectedPropertyID()
  const { user } = useUser()
  const email = user?.attributes?.email

  const NAV_HEADER_ITEMS: NavItem[] = [
    {
      id: 'bookingListNavHeader',
      page: t('bookingList'),
      url: '/bookings/list',
      permissions: 'Home',
      brand: ['awaze']
    },
    {
      id: 'bookingListNavHeaderNewNovasol',
      page: t('bookingList'),
      url: '/bookings/list',
      permissions: 'Home',
      productMarkets: ['Denmark'],
      brand: ['novasol']
    },
    {
      id: 'bookingListNavHeaderOldNovasol',
      page: t('bookingList'),
      url: `/rental/${propertyID}/bookings`,
      permissions: 'Home',
      excludedProductMarkets: ['Denmark'],
      brand: ['novasol']
    },
    {
      id: 'chartNavHeader',
      page: t('chart'),
      url: '/bookings/chart',
      permissions: 'Chart'
    },
    {
      id: 'calendarNavHeader',
      page: t('calendar'),
      url: '/bookings/calendar',
      permissions: 'Calendar',
      brand: ['awaze']
    },
    {
      id: 'calendarNavHeaderNewNovasol',
      page: t('calendar'),
      url: '/bookings/calendar',
      permissions: 'Calendar',
      productMarkets: ['Denmark'],
      brand: ['novasol']
    },
    {
      id: 'calendarNavHeaderOldNovasol',
      page: t('calendar'),
      url: `/rental/${propertyID}`,
      permissions: 'Calendar',
      excludedProductMarkets: ['Denmark'],
      brand: ['novasol']
    },
    {
      id: 'pricingNavHeader',
      page: t('pricing'),
      url: '/pricing-list',
      permissions: 'Pricing',
      brand: ['awaze']
    }, {
      id: 'pricingNavHeader',
      page: t('pricing'),
      url: `/rental/${propertyID}/prices`,
      permissions: 'Pricing',
      brand: ['novasol']
    },
    {
      id: 'financialNavHeader',
      page: t('financial'),
      permissions: 'Financial',
      brand: ['awaze', 'novasol'],
      links: [
        {
          id: 'allowancesAndChargesNavHeader',
          label: t('bookingAllowancesCharges'),
          url: '/financial/booking-allowance',
          permissions: 'Financial',
          brand: ['awaze']
        },
        {
          id: 'onlineStatementsNavHeader',
          label: t('onlineStatements'),
          url: '/financial/statements',
          permissions: 'Financial',
          brand: ['awaze']
        },
        {
          id: 'keyFiguresNavHeader',
          label: t('keyFigures'),
          url: `/rental/${propertyID}/keyfigures`,
          permissions: 'Financial',
          brand: ['novasol']
        },
        {
          id: 'documentsNavHeader',
          label: t('documents'),
          url: `/rental/${propertyID}/documents`,
          permissions: 'Financial',
          brand: ['novasol']
        }
      ]
    },
    {
      id: 'reports',
      page: 'Reports',
      url: `/reports`,
      permissions: 'viewSiteReports',
      brand: ['awaze']
    },
    {
      id: 'myPropertyNavHeader',
      page: t('property'),
      permissions: 'Property',
      links: [
        {
          id: 'amendmentsNavHeader',
          label: t('amendments'),
          url: '/property/amends',
          permissions: 'None',
          brand: ['awaze']
        },
        {
          id: 'qualityGradingNavHeader',
          label: t('qualityGrading'),
          href: isEnabled('vite') ? '/awaze-portal/pdf/grading-scheme.pdf' : require('@/assets/Grading Schema.pdf'),
          permissions: 'None',
          target: '_blank',
          brand: ['awaze']
        },
        {
          id: 'guestReviewsNavHeader',
          label: t('guestReviews'),
          url: '/property/reviews',
          permissions: 'None',
          brand: ['awaze', 'novasol']
        },
        {
          id: 'healthAndSafetyNavHeader',
          label: t('healthAndSafety'),
          url: '/health-and-safety',
          permissions: 'None',
          brand: ['awaze']
        },
        {
          id: 'propertDetailsNavHeader',
          label: t('propertyDetails'),
          url: `/rental/${propertyID}/details`,
          permissions: 'None',
          brand: ['novasol']
        },
        {
          id: 'contactsNavHeader',
          label: t('contacts'),
          url: `/rental/${propertyID}/contacts`,
          permissions: 'None',
          brand: ['novasol']
        }
      ]
    },
    {
      id: 'caretakersNavHeader',
      page: t('caretakers'),
      url: '/caretakers',
      permissions: 'Caretakers',
      brand: ['awaze']
    },
    {
      id: 'usersNavHeader',
      page: t('users'),
      permissions: 'Admin',
      url: '/user-admin'
    },
    {
      id: 'ownerBookingNavHeader',
      page: t('ownerBooking'),
      url: `/rental/${propertyID}/blocking`,
      excludedProductMarkets: ['Denmark'],
      permissions: 'CreateBooking',
      brand: ['novasol']
    },
    {
      id: 'caretakersServicesNavHeader',
      page: t('caretakersServices'),
      permissions: 'destinationService',
      brand: ['novasol'],
      links: [
        {
          id: 'dashboardNavHeader',
          label: t('dashboard'),
          url: '/dashboard',
          permissions: 'None',
          brand: ['novasol']
        },
        {
          id: 'arrivalsAndDeparturesNavHeader',
          label: t('arrivalsAndDepartures'),
          url: '/arrivals-departures',
          permissions: 'None',
          brand: ['novasol']
        },
        {
          id: 'occupancyNavHeader',
          label: t('occupancy'),
          url: '/occupancy',
          permissions: 'None',
          brand: ['novasol']
        },
        {
          id: 'propertyListNavHeader',
          label: t('propertyList'),
          url: '/property-list',
          permissions: 'None',
          brand: ['novasol']
        }
      ]
    }
  ]

  const NAV_PRE_HEADER_ITEMS = [
    {
      id: 'needHelpNavHeader',
      page: t('needHelp'),
      href: '/contact-us',
      permissions: 'help',
      brand: ['awaze']
    },
    {
      id: 'needHelpNavHeader',
      page: t('needHelp'),
      url: '/contact',
      permissions: 'help',
      brand: ['novasol']
    },
    // TODO - remove conditional nav items when owner-services-mfe is enabled
    ...(
      isEnabled('owner-services-mfe') ? [
        {
          id: 'ownerServicesNavHeader',
          page: t('information'),
          permissions: 'News',
          brand: ['awaze', 'novasol'],
          links: [
            {
              id: 'newsNavHeader',
              label: t('news'),
              url: '/news',
              permissions: 'News',
              brand: ['awaze', 'novasol']
            },
            {
              id: 'guidesNavHeader',
              label: t('guides'),
              url: '/articles/guides',
              permissions: 'News',
              brand: ['awaze', 'novasol']
            }
          ]
        }
      ] : [
        {
          id: 'newsNavHeader',
          page: t('news'),
          url: '/news',
          permissions: 'News',
          brand: ['awaze']
        },
        {
          id: 'newsNavHeader',
          page: t('news'),
          permissions: 'News',
          brand: ['novasol'],
          links: [
            {
              id: 'allnewsNavHeader',
              label: t('allNews'),
              url: '/articles/191',
              permissions: 'News'
            },
            {
              id: 'newOwnerNavHeader',
              label: t('newOwner'),
              url: '/articles/190',
              permissions: 'News'
            },
            {
              id: 'urgentInfoNavHeader',
              label: t('importantInformation'),
              url: '/articles/253',
              permissions: 'News'
            }
          ]
        }
      ]
    ),
    {
      id: 'accountNavHeader',
      page: t('account'),
      permissions: 'None',
      links: [
        {
          id: 'emailNavHeader',
          label: email,
          permissions: 'None'
        },
        {
          id: 'accountDetailsNavHeader',
          label: t('accountDetails'),
          url: '/personal/account-details',
          permissions: 'AccountDetails',
          brand: ['awaze']
        },
        {
          id: 'accountDetailsNavHeader',
          label: t('accountDetails'),
          url: '/account',
          permissions: 'AccountDetails',
          brand: ['novasol']
        },
        {
          id: 'benefitsNavHeader',
          label: t('benefits'),
          url: `/articles/5`,
          brand: ['novasol'],
          permissions: 'None'
          // permissions: 'Benefits'
        }, {
          id: 'benefitsNavHeader',
          label: t('benefits'),
          url: '/benefits',
          brand: ['awaze'],
          permissions: 'Benefits'
        },
        {
          id: 'logoutNavHeader',
          label: t('logout'),
          url: '/signout',
          permissions: 'None'
        }
      ]
    }
  ]

  return {
    NAV_HEADER_ITEMS,
    NAV_PRE_HEADER_ITEMS
  }
}
