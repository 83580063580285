import Cookies from 'js-cookie'

import dayjs from 'dayjs'
import { registerLocale, setDefaultLocale } from 'react-datepicker'

export function getLocale () {
  const userLanguage = Cookies.get('Drupal.visitor.awaze_user_language') || Cookies.get('lang') || 'en'
  return userLanguage
}

/** Ensure we don't return "debug" or other unknown locales */
function normalizeLocale (locale) {
  switch (locale) {
    case 'en-GB':
    case 'hr':
    case 'da':
    case 'es':
    case 'fr':
    case 'it':
    case 'nb':
    case 'sv':
    case 'de':
    case 'nl':
    case 'pl':
    case 'sl':
      return locale
    case 'debug':
    default:
      return 'en'
  }
}

async function loadDayjsLocale (locale) {
  switch (locale) {
    case 'hr': return import('dayjs/locale/hr')
    case 'da': return import('dayjs/locale/da')
    case 'es': return import('dayjs/locale/es')
    case 'fr': return import('dayjs/locale/fr')
    case 'it': return import('dayjs/locale/it')
    case 'nb': return import('dayjs/locale/nb')
    case 'sv': return import('dayjs/locale/sv')
    case 'de': return import('dayjs/locale/de')
    case 'nl': return import('dayjs/locale/nl')
    case 'pl': return import('dayjs/locale/pl')
    case 'sl': return import('dayjs/locale/sl')
    case 'en-GB':
    case 'debug':
    default: return import('dayjs/locale/en')
  }
}

async function loadReactDatePickerLocale (localeName) {
  const locale = await import('date-fns/locale/' + localeName)
  registerLocale(localeName, locale.default)
}

export const setLocale = async (locale) => {
  let currentLocale = locale || getLocale()

  try {
    currentLocale = normalizeLocale(currentLocale)

    await Promise.all([
      loadDayjsLocale(currentLocale),
      loadReactDatePickerLocale(currentLocale)
    ])

    dayjs.locale(currentLocale === 'en-GB' ? 'en' : currentLocale)
    setDefaultLocale(currentLocale)
  } catch (error) {
    dayjs.locale('en')
    setDefaultLocale('en-GB')
  }
}
