import { memoize } from '@/helpers/memoize'

export const generateUserFriendlyDateFormat = memoize((isoDate) => {
  const date = new Date(isoDate)
  const day = date.toLocaleString('default', { day: '2-digit' })
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.toLocaleString('default', { year: 'numeric' })
  return day + '-' + month + '-' + year
})

export function formatShortMonthDate (dateStr) {
  const date = new Date(dateStr)
  const options = { day: '2-digit', month: 'short', year: 'numeric' }
  return date.toLocaleDateString('default', options)
}
export function formatToNumericDate (dateStr) {
  const date = new Date(dateStr)
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
  return date.toLocaleDateString('default', options)
}

export function getIsoDateWithoutTimezoneMapping (date) {
  const yearValue = date.getFullYear()
  const monthValue = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
  const dateValue = (date.getDate()) < 10 ? '0' + date.getDate() : date.getDate()
  return `${yearValue}-${monthValue}-${dateValue}`
}

export function getIsoDate (date) {
  return date.toISOString().substring(0, 10)
}

export function removeTimeFromIsoDateString (dateString) {
  return dateString.substring(0, 10)
}

export function isToday (date) {
  return generateUserFriendlyDateFormat(date) === generateUserFriendlyDateFormat(new Date())
}

export function addDaysToDate (date, daysToAdd) {
  const dateToAddTo = new Date(date)
  dateToAddTo.setDate(dateToAddTo.getDate() + daysToAdd)
  return dateToAddTo
}

export function deductDaysFromDate (date, daysToDeduct) {
  const dateToDeductFrom = new Date(date)
  dateToDeductFrom.setDate(dateToDeductFrom.getDate() - daysToDeduct)
  return dateToDeductFrom
}

export function getUserFriendlyDayFromDate (date) {
  const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  return days[date.getDay()]
}

export function getUserFriendlyMonthFromDate (date) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return months[date.getMonth()]
}

export function getUserFriendlyDateNumberFromDate (date) {
  const dateNumber = date.getDate()

  if (dateNumber < 10) { return `0${dateNumber}` }

  return `${dateNumber}`
}
