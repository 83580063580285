import styled from 'styled-components'
import { Button } from '@awaze/design-system.components.button'

export const ErrorContent = styled.div`
  margin-bottom: 2rem;
`

export const StyledIconAndHeader = styled.div`
  display: inline-flex;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    flex-direction: column;
    align-items: center; 
  }
`

export const TextWithMargin = styled.span`
  margin-left: 1rem;
  font-size: 1.5rem; 

  
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    margin-left: 0;
    margin-top: 1rem;
    font-size: 1.25rem;  
    margin-bottom: 0.75rem;  
  }
`

export const StyledButton = styled(Button)`
  width: 7.75rem;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 100%;  
  }
`
