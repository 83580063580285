import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import Button from '@/components/__Inputs/Button'
import { PageWrapper, Spinner } from '@/components/__UI'
import { PricingList, PricingListControls, PricingInfoModal } from '@/components/__PricingComponents'
import useGraphQl, { GET_USER_PROPERTY_YEARS_DURATIONS } from '@/hooks/useGraphQl'
import { FloorPrice, MessageWrap } from './PricingListPage.styles'
import { CallToArmsProvider } from '../../components/__PriceCallToArms/context/CallToArmsProvider'
import CallToArmsMessage from '../../components/__PriceCallToArms/CallToArmsMessage/CallToArmsMessage'
import { useQueryUserPropertyPricing } from '@/hooks/useQuery'

const PricingListPage = () => {
  const durationLabels = {
    2: '2 nights (Fri-Sun)',
    3: '3 nights (Fri-Mon)',
    4: '4 nights (Mon-Fri)',
    7: '7 nights (Week)',
    14: '14 nights (Fortnight)'
  }

  const [ infoModalOpen, setInfoModalOpen ] = useState(false)
  const [ yearList, setYearList ] = useState([])
  const [ priceList, setPriceList ] = useState([])
  const [ floorPrice, setFloorPrice ] = useState(null)
  const [ durationList, setDurationList ] = useState([])
  const [ selectedYear, setSelectedYear ] = useState(null)
  const [ selectedDuration, setSelectedDuration ] = useState(null)
  const [ selectedProperty, setSelectedProperty ] = useState(null)
  const [ hasError, setHasError ] = useState(false)

  useEffect(() => {
    return () => {
      setInfoModalOpen(false)
      setYearList([])
      setPriceList([])
      setFloorPrice(null)
      setDurationList([])
      setSelectedYear(null)
      setSelectedDuration(null)
      setSelectedProperty(null)
      setHasError(false)
    }
  }, [])

  const tooltips = {
    incomeTooltip: 'Calculated on today’s customer price, the income you will receive after our agency commission, inclusive of VAT and if your property is in dynamic pricing, reflects any price adjustments',
    customerPriceTooltip: 'All displayed prices are exclusive of our booking fee',
    decreasePercentTooltip: 'If you have opted into dynamic pricing this is your max % decrease',
    increasePercentTooltip: 'If you have opted into dynamic pricing this is your max % increase'
  }

  const mapToOptions = (items, labels) =>
    Array.isArray(items)
      ? items.map(item => ({ label: labels ? labels[item] : item, value: item }))
      : { label: labels ? labels[items] : items, value: items }

  const { loading: isLoadingDropdowns } = useGraphQl({
    query: GET_USER_PROPERTY_YEARS_DURATIONS,
    variables: { propertyid: selectedProperty ? selectedProperty.propertyId : null },
    asObject: true,
    onSuccess: (data) => {
      const hasYears = (data.years && data.years.length)
      const hasDurations = !!(data.durations && data.durations.length)
      const currentYearIndex = data.years.findIndex(y => y === +dayjs().format('YYYY'))

      setYearList(hasYears ? mapToOptions(data.years) : [])
      setDurationList(hasDurations ? mapToOptions(data.durations, durationLabels) : [])
      setSelectedDuration(hasDurations ? mapToOptions(data.durations.includes(7) ? 7 : data.durations[0], durationLabels) : null)
      setSelectedYear(hasYears ? (data.years[currentYearIndex] ? mapToOptions(data.years[currentYearIndex]) : mapToOptions(findClosestYear(data.years))) : null)
    }
  })

  const { data, isLoading: isLoadingPricingData, isError, error, refetch: reloadPricingData } = useQueryUserPropertyPricing({
    propertyId: selectedProperty ? selectedProperty.propertyId : null,
    numberNights: selectedDuration ? selectedDuration.value : null,
    startYear: selectedYear ? selectedYear.value : null,
    endYear: selectedYear ? selectedYear.value : null})

  useEffect(() => {
    if (data) {
      setFloorPrice(data.floorPrice || null)
      setPriceList(data.pricingDataRows || [])
    }
    if (isError) {
      setHasError(true)
    }
  }, [data, isError, error])

  const findClosestYear = (yearsArray) => {
    const currentYear = new Date().getFullYear()
    let closestYear = yearsArray[0]
    let smallestDifference = Math.abs(currentYear - closestYear)

    for (let i = 1; i < yearsArray.length; i++) {
      const difference = Math.abs(currentYear - yearsArray[i])
      if (difference < smallestDifference || (difference === smallestDifference && yearsArray[i] > closestYear)) {
        smallestDifference = difference
        closestYear = yearsArray[i]
      }
    }

    return closestYear
  }

  const renderPriceList = () => {
    if (hasError) {
      return (
        <MessageWrap>
          <h1>There was an error loading pricing data, please try again or select different options.</h1>
          <Button primary onClick={() => reloadPricingData()}>Try again</Button>
        </MessageWrap>
      )
    }

    if (isLoadingPricingData) return <Spinner large />

    if (!priceList.length) {
      return (
        <MessageWrap>
          <h1>No pricing data found</h1>
        </MessageWrap>
      )
    }

    return (
      <PricingList
        duration={selectedDuration}
        items={priceList}
        loading={isLoadingPricingData}
        year={selectedYear}
        tooltips={tooltips}
      />
    )
  }

  return (
    <CallToArmsProvider>
      <PageWrapper
        title='Pricing'
      >
        {infoModalOpen && <PricingInfoModal onClose={setInfoModalOpen} />}

        <div>
          <p>The list below shows the income you will receive after our agency commission, is inclusive of VAT and if your property is in dynamic pricing, reflects any price adjustments.</p>
          <Button data-testid="pricingReadMoreLink" link style={{marginTop: '0.5rem', textAlign: 'left'}} onClick={() => setInfoModalOpen(true)}>Read more about pricing and dynamic pricing</Button>
        </div>

        <CallToArmsMessage />

        <PricingListControls
          onPropertiesLoaded={(data) => setSelectedProperty((data && data.length) ? data[0] : null)}
          onPropertyChange={setSelectedProperty}
          yearData={{
            value: selectedYear,
            options: yearList,
            onChange: setSelectedYear,
            loading: isLoadingDropdowns
          }}
          durationData={{
            value: selectedDuration,
            options: durationList,
            onChange: setSelectedDuration,
            loading: isLoadingDropdowns
          }}
          tooltips={tooltips}
        />

        <div>
          {renderPriceList()}
        </div>

        <FloorPrice value={floorPrice} />

      </PageWrapper>
    </CallToArmsProvider>
  )
}

export default PricingListPage
