import styled from 'styled-components'
import { Flex } from 'reflexbox/styled-components'
import { getConsolidatedBookingColor } from '@/helpers/bookings'
import { Heading } from '@awaze/design-system.components.text'

const BookingType = styled(Heading)`
  display: flex;
  align-items: center;

  font-size: 1.5rem;

  &:before {
    content: '';
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    background: ${({ bookingState }) => getConsolidatedBookingColor(bookingState)};
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    font-size: 1.25rem;
  }
`

const PropertyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  ${({ theme }) => theme.fonts.Text300}
`

const PropertyName = styled.h4`
  font-weight: bold;
`

const PropertyId = styled.small`
  display: flex;
`

const BookingDetails = styled(Flex)`
  width: auto;
  font-size: 0.75rem;

  h4 {
    margin-bottom: 1rem;
  }

  td {
    padding: 0 0 1rem;
  }

  td:nth-child(2) {
    font-weight: 600;
    padding-left: 2rem; 
  }
`

const StyledTable = styled.table`
  width: 100%;
`

const ConfirmationMessage = styled.div`
  margin-bottom: 2rem;

  h3 {
    font-weight: 600;
  }
`

const ErrorMessage = styled(ConfirmationMessage)`
  background-color: ${({theme}) => theme.colors.bookingModalBackgroundError};
  padding: 1rem;
  color: ${({theme}) => theme.colors.white};
  line-height: 1.8em;
  border-radius: 0.1875rem;

  b {
    font-weight: bold;
  }
`

const ErrorText = styled.span`
  color: ${({theme}) => theme.colors.error};
  font-size: 0.75em; 
`

const ExcessCharges = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.bookingModalBackgroundExcessCharges};
  padding: 0.5rem 1rem;
  border-radius: 0.1875rem; 
  height: 100%;
  pointer-events: none;

  &::before {
    position: absolute;
    top: -0.8rem;
    left: -0.8rem;
    content: "!";
    height: 1.5625rem;
    width: 1.5625rem;
    background-color: ${({theme}) => theme.colors.bookingModalBackgroundExcessChargesBefore};
    border-radius: 50%;
    color: ${({theme}) => theme.colors.white};
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.5625rem;
  }

  h4 {
    font-weight: 600;
  }

  p {
    margin-top: 1rem;
  }
`

export {
  BookingType,
  PropertyName,
  PropertyId,
  BookingDetails,
  StyledTable,
  ConfirmationMessage,
  ErrorMessage,
  ErrorText,
  ExcessCharges,
  PropertyContainer
}
