import {
  PRICE_REC_TABLE_INCLUDED_NOVASOL_COLUMNS_DESKTOP,
  PRICE_REC_TABLE_INCLUDED_AWAZE_COLUMNS_DESKTOP,
  PRICE_REC_TABLE_INCLUDED_NOVASOL_COLUMNS_MOBILE,
  PRICE_REC_TABLE_INCLUDED_AWAZE_COLUMNS_MOBILE
} from '../PriceRecommendationHelpers/constants'

export const getIncludedColumnsDesktop = (isNovasol) => {
  if (isNovasol) return PRICE_REC_TABLE_INCLUDED_NOVASOL_COLUMNS_DESKTOP
  else return PRICE_REC_TABLE_INCLUDED_AWAZE_COLUMNS_DESKTOP
}

export const getIncludedColumnsMobile = (isNovasol) => {
  if (isNovasol) return PRICE_REC_TABLE_INCLUDED_NOVASOL_COLUMNS_MOBILE
  else return PRICE_REC_TABLE_INCLUDED_AWAZE_COLUMNS_MOBILE
}
