import styled from 'styled-components'
import {PropertyDropdownDS} from '@/components/__Inputs/PropertyDropdown'
import { Link } from 'react-router-dom'
import { Text } from '@awaze/design-system.components.text'

export const PageWrapper = styled.div`
  width: 792px; 
  max-width: 792px;
  min-height: 60vh;
  max-width: 75rem;
  padding: 2rem 0 2rem;

${({ theme }) => theme.mediaQueries.phoneOnly} {
  width: calc(100% - 2rem);
}
 
@media (min-width: 600px) and (max-width: 1024px) {
  width: calc(100% - 2rem);
}
`
export const PropertyDropdown = styled(PropertyDropdownDS)`
  margin: 0;
  height: 3rem;
`

export const PropertyDropdownWrapper = styled.div`
  max-width: min(350px, 35%);
  margin: 1.5rem 0;

${({ theme }) => theme.mediaQueries.phoneOnly} {
  max-width: 100%;
  margin: 1rem 0 1.25rem;
}
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.link}; 
  font-weight: normal;

&:hover, &:active {
  font-weight: normal;
}
`
export const TextInline = styled(Text)`
  display: 'inline';

`
