import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Item, ItemColor, StyledIcon } from './Legend.styles'
import Tooltip from '../Tooltip'

const Legend = ({ items, tooltipText, onClick }) => (
  <Wrapper>
    {items.map(({ label, color, radius, icon }) => (
      <Item key={label}>
        {icon ? (
          <StyledIcon src={icon} alt={label} />
        ) : (
          <ItemColor color={color} radius={radius}>
          </ItemColor>
        )}
        <span>{label}</span>
      </Item>
    ))}
    {tooltipText && <Tooltip text={tooltipText} onClick={onClick} />}
  </Wrapper>
)

Legend.displayName = 'Legend'

Legend.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
    icon: PropTypes.string,
    radius: PropTypes.string
  })).isRequired,
  tooltipText: PropTypes.string,
  onClick: PropTypes.func
}

export default Legend
