import Sticky from 'react-sticky-el'
import styled from 'styled-components'
import { PropertyDropdown, SelectDropdown } from '@/components/__Inputs'

const StyledSelect = styled(SelectDropdown)`margin: 0.5rem 0 0.5rem 0;`
const StyledPropertySelect = styled(PropertyDropdown)`margin: 0.5rem 0 0.5rem 0;`

const ListHeader = styled.div`
  margin-top: 2rem;
  position: sticky;
  top: 0rem;
  padding: 0.5rem 0;
  z-index: 4;
  background: ${({theme}) => theme.colors.bgColor};

  @media (min-width: 768px) and (max-width: 900px) {
    top: 8rem;
  }
  
  .list-header-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
    margin-bottom: 1rem;
    margin-right: 1rem;
    @media (min-width: 600px) and (max-width: 699px) {
      width: 100%;
      max-width: 100%;
      flex-direction: column;
    }
    @media (min-width: 700px) and (max-width: 967px) {
      width: 100%;
      max-width: 100%;
    }

    & > * {
      flex: 1;
      margin-right: 0.5rem;
      @media (min-width: 600px) and (max-width: 700px) {
        flex: 1;
      }
    }

    .list-control {
      display: inline-block;
    }
  }

  .priceRecommendationsLInk{
    height: 2.75rem;
    width: auto;
    margin-top: 2.1rem;
    border: 1px solid ${({theme}) => theme.colors.error};
    border-radius: .25rem;
    background-color: ${({theme}) => theme.colors.white};

    a:link{
      text-decoration: none; 
      color: ${({theme}) => theme.colors.attention};
      font-size: 1.2rem;
    }

    .priceRecommendationsButtonName{
      color: ${({theme}) => theme.colors.white};
      font-size: 1.2rem;
      background-color: ${({theme}) => theme.colors.error};
    }
    .priceRecommendationsButtonName:hover{
      color:${({theme}) => theme.colors.attention};
      background-color: ${({theme}) => theme.colors.greyFive};
      transition: 0.7s ease-in-out;
    }
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    align-items: baseline;
    flex-direction: column;

    .list-header-inner {
      width: 100%;
      display: block;
      margin-top: 1rem;
      text-align: right;

      .list-control {
        margin-bottom: 1rem;
      }

      button {
        width: 8rem;
        margin-top: .5rem;
      }
    }
  }
`
// MOBILE VERSION
const YearControl = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 2rem;

  .year-control-inner {
    display: flex;
    padding: 0.8rem;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    justify-content: space-around;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    background-color: ${({ theme }) => theme.colors.primary};


    .arrow {
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        transform: rotate(45deg);
      }

      &.left::before {
        border-left: 2px solid ${({ theme }) => theme.colors.white};
        border-bottom: 2px solid ${({ theme }) => theme.colors.white};
      }

      &.right::before {
        border-top: 2px solid ${({ theme }) => theme.colors.white};
        border-right: 2px solid ${({ theme }) => theme.colors.white};
      }

      &[disabled] {
        visibility: hidden;
      }
    }
  }

  .year-control-items {
    padding: 0.8rem;
    font-size: 0.8rem;
    background: ${({ theme }) => theme.colors.white};

    ${({ theme }) => theme.mediaQueries.tabletLandscapeUp} {
      display: flex;
    }
  }

`

const WeeklyStatsMenu = styled(Sticky)`
  .year-control-headers {
    display: flex;
    font-weight: 600;
    text-align: center;
    justify-content: space-around;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 6px 8px 0px ${({ theme }) => theme.colors.pricingBoxShadow};
    p {
      padding: 0.5rem;
      font-size: 0.9375rem;
    }
  }
`

export { StyledSelect, StyledPropertySelect, ListHeader, YearControl, WeeklyStatsMenu }
