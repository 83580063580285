import styled from 'styled-components'
import BookingDetails from '@/components/__BookingComponents/BookingDetails'
import { Button } from '@awaze/design-system.components.button'
import { Text } from '@awaze/design-system.components.text'
import { ItemsContainer } from '@/components/__BookingComponents/BookingDetails/BookingDetails.styles'

export const BookingDetailsStyled = styled(BookingDetails)`
    margin-bottom: 1.5rem; 

    ${ItemsContainer} {
    margin-bottom: 1.5rem;
  }

  @media (min-width: 769px) {
    ${ItemsContainer} {
      --item-column-size: 12rem;
    }
  }
`

export const StyledText = styled(Text)`
    margin-left: 1.75rem;
`

export const StyledIconAndHeader = styled.div` 
    display: inline-flex;
    padding: 1rem 0rem;
  

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
    margin-top: 1rem;
    flex-direction: column;
    align-items: center; 
    }
`

export const TextWithMargin = styled.span`
    margin-left: 1rem;
    font-size: 1.5rem;

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
        margin-left: 0;
        margin-top: 1rem; 
        font-size: 1.25rem;  
        margin-bottom: 0.75rem; 
    }
`

export const FlexWrap = styled.div`
    display: flex;
    gap: 0.5rem; 
`

export const ErrorMessage = styled.div`
    background-color: ${({ theme }) => theme.colors.bookingModalBackgroundError};
    padding: 1rem;
    color: ${({ theme }) => theme.colors.white};
    line-height: 1.8em;
    border-radius: 0.1875rem;

    b {
        font-weight: bold;
    }
`

export const OvalLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledButton = styled(Button)`
    width: 7.75rem; 

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
        width: 100%;  
    }
`
export const ButtonContent = styled.div`
    margin-top: 1.5rem;
`
