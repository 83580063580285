import styled from 'styled-components'
import { Flex, Box } from 'reflexbox/styled-components'
import { Button } from '@awaze/design-system.components.button'

export const GridWrap = styled(Flex)`
    margin: 1em 0;
    flex-wrap: wrap;
    border-radius: ${({theme}) => theme.borderRadius};
    
    ${({theme}) => theme.mediaQueries.phoneOnly} {
      margin: 0;
    }

`

export const StyledPreviousButton = styled(Button)`
  ${({ theme }) => theme.fonts.Link300}

  @media (max-width: 1024px) {
    margin: 1rem auto;
    display: block;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    margin-top: ${({ hasSwitcher }) => (hasSwitcher ? '0rem' : '1rem')}; 
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    margin-top: ${({ hasSwitcher }) => (hasSwitcher ? '1rem' : '1.5rem')}; 
    margin-bottom: 0.5rem;
  }
`

export const CalendarWrapper = styled.div`
  ${({yearCalendar, theme}) => yearCalendar ? `
    background-color: ${theme.colors.white};
    padding: 0.625rem; /* 10px */
    border-radius: 0.375rem; /* 6px */
    border: 0.5px solid ${theme.colors.tertiaryButtonBorderHover};

    ${Box} {
      margin-bottom: 0;
    }
  ` : `
    ${theme.mediaQueries.phoneOnly} {
      margin: 0 -1.125rem; /* 0 -18px */
    }
`}
`

export const Details = styled.div`
  ${({ theme }) => `
    margin: 1em 0;

    td {
      padding: 0.5em 0;
    }

    ${theme.mediaQueries.phoneOnly} {
      td {
        font-size: 0.9em;
      }
    }
  `}
`

export const Year = styled.h2`
  text-align: center;
  margin: 0 1rem;
`

export const SwitcherContainer = styled.div`
  margin: 0.5rem 0 0 2.5rem;
  display: flex;
  gap: 1rem;
`

export const SwitcherContainerMobileTablets = styled.div`
${({ theme }) => theme.mediaQueries.phoneOnly} {
  margin-left: 1rem;
}`
