/* stylelint-disable no-descending-specificity */
import styled from 'styled-components'
import DesignTokens from '@/global/DesignTokens'
import Button from '@/components/__Inputs/Button'
import { getConsolidatedBookingColor } from '@/helpers/bookings'

export const BookingListCardBookingType = styled.p`
    box-sizing: border-box;
    width: 0.3rem;
    height: 3rem;
    border-radius: 10px;
    background: ${({bookingState}) => getConsolidatedBookingColor(bookingState)};
    margin-left: 0.5rem;
    @media screen and (max-width: 1024px) {
        height: auto;
    }
`
export const HiddenRow = styled.div`
    width: 100%;

    @media screen and (max-width: 1024px) {
        border: 0;
        background-color:  ${({ theme }) => theme.colors.white};
        border-bottom-right-radius: ${({theme}) => theme.borderRadius};
        border-bottom-left-radius: ${({theme}) => theme.borderRadius};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
`
export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px, rgba(0, 0, 0, 0.1) 0px 0.3px 0.5px 0px;
`
export const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.fonts.Text300};
    border-radius: ${({ theme }) => theme.borderRadius};
    cursor: pointer;
`
export const CardInfoContainer = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;

    .dateTypeBox{
        display: flex; 
        width: 4.2rem;
        height: auto;

        ${({theme}) => theme.mediaQueries.phoneOnly} {
            margin-right: 1rem;
        }

        .dateBox{
            display: flex; 
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            width: 2.5rem;
            color: ${DesignTokens.ColorGrey800};
            font-weight: 500;

            .endDateColor{
            color: ${DesignTokens.ColorGrey500}; 
            }
        }
    }

    .items{
        display: flex; 
        flex-direction: column;
        justify-content: flex-start;
    }

    .arrowBtn {
        width: 2rem;
        display: flex;
        align-items: flex-end;
        padding-bottom: 1rem;
    }

    .startDate{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0.5rem;
        font-weight: 600;
    }
    .endDate{
        padding-right: 0.5rem;
    }
`
export const CardMainContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`
export const SpecialRequestIcon = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    p{
        margin-left: 0.5rem;
    }
`

export const StyledBookingDetails = styled.div`
    font-size: 1.3vw;
    max-width: 80rem;
    padding: 1.5rem 1rem;

    h4 {
        font-size: 1rem;
        max-width: 20rem;
    }

    table {
        table-layout: fixed;

        td:nth-child(1) {
            width: 4rem;
            margin-right: 1rem;
            word-wrap: break-word;
            font-weight: 600;

            ${({ theme }) => theme.mediaQueries.phoneOnly} {
            width: 8rem;
            }
        }

        td:nth-child(2) {
            padding-left: 0;
            width: 10rem;
            font-weight: normal;
        }
    }

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
        width: 100%;
        table {
            td:nth-child(1) {
                width: 7rem;
            }
        }
    }

    @media only screen and (max-width: 340px) {
        table {
            td:nth-child(1) {
                width: 5rem;
            }

            td:nth-child(2) {
                padding-left: 0.5rem;
            }
        }
    }
`
export const CancelButton = styled(Button)`
    width: auto;
    height: auto;
    margin: 1rem auto;
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
    background-color: transparent;

    &:hover{
        color: ${DesignTokens.ColorButtonSolidSecondaryBackgroundHover};
        border: 2px solid ${DesignTokens.ColorButtonSolidSecondaryBackgroundHover};
        background: #f9f9f9;
        transition: all 0.4s ease-in-out;
    }

    ${({theme}) => theme.mediaQueries.phoneOnly} {
        width: 70%;
    }
`
export const ButtonContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        margin-left: 1rem;
        margin-right: 12px;
    }
    p{
        margin-right: 1rem;
    }
`
