import styled from 'styled-components'

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    display: block;
    max-width: fit-content;
    margin: 0;
    white-space: nowrap;
  }
`
const SpinnerContainer = styled.div`
  position: absolute;
  top: 47% !important;
  left: 48.5%;
  z-index: 2;
`
const ForwardArrowContainer = styled.div`
  position: sticky;
`

export { Table, SpinnerContainer, ForwardArrowContainer }
