import styled from 'styled-components'

export const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  row-gap: 0.5rem;
  column-gap: 1.5rem;

${({ theme }) => theme.mediaQueries.phoneOnly} {
  grid-template-columns: repeat(1, 1fr);
}
`

export const CategoryItem = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center; 

`
