import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQueryPropertyPricingSummary } from '@/hooks/useQuery'
import useUserBrand from '@/hooks/useUserBrand'

export const callToArmsContextDefaults = {
  showRecommendationButton: false,
  filteredProperties: []
}

export const CallToArmsContext = createContext(callToArmsContextDefaults)
export const useCallToArmsContext = () => useContext(CallToArmsContext)

export const CallToArmsProvider = ({ children }) => {
  const [filteredProperties, setFilteredProperties] = useState(null)
  const [showRecommendationButton, setShowRecommendationButton] = useState(false)
  const { isNovasol } = useUserBrand()

  const { data, error } = useQueryPropertyPricingSummary()

  useEffect(() => {
    if (error) {
      setShowRecommendationButton(false)
      setFilteredProperties([])
    } else if (data) {
      setShowRecommendationButton(data.showPricingRecommendations)
      setFilteredProperties(data.referenceData?.length ? data.referenceData : [])
    }
  }, [data, error])

  const showPricingRecommendation = isNovasol ? false : showRecommendationButton

  return (
    <CallToArmsContext.Provider value={{
      showRecommendationButton: showPricingRecommendation,
      filteredProperties: filteredProperties
    }}>
      {children}
    </CallToArmsContext.Provider>
  )
}

CallToArmsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
