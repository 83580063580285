import dayjs from 'dayjs'
import { ExportToCsv } from 'export-to-csv'
import { DATE_FORMAT_DISPLAY } from '@/constants/dates'
import { getLocale } from '@/helpers/locale'

const createCSVFile = ({ data, options }) => {
  const csvExporter = new ExportToCsv(options)

  return csvExporter.generateCsv(data)
}

const createCsvFromReport = (filename, reportData, t) => {
  const headers = [
    t('ownerCodeCSV'),
    t('commissionRateCSV'),
    t('serviceNameCSV'),
    t('unitCodeCSV'),
    t('startDateCSV'),
    t('finishDateCSV'),
    t('bookingIdCSV'),
    t('bookingStatusCSV'),
    t('titleCSV'),
    t('forenameCSV'),
    t('surnameCSV'),
    t('netHireCSV'),
    t('vatCSV'),
    t('fuelSecurityCSV'),
    t('extrasCSV'),
    t('totalCostCSV'),
    t('hoseasonsHeldCSV'),
    t('outstandingCSV'),
    t('amountPaidCSV'),
    t('paymentTypeCSV'),
    t('serviceCodeCSV'),
    t('fuelCSV'),
    t('supplierCodeCSV')
  ]

  const csvData = reportData.map(item => [
    item.ownerCode,
    item.commissionRate,
    item.serviceName,
    item.unitCode,
    item.start,
    item.finish,
    item.bookingId,
    item.bookingStatus,
    item.title,
    item.foreName,
    item.surName,
    item.nettHire,
    item.vat,
    item.fuelSecurity,
    item.extras,
    item.totalCost,
    item.hoseasonsHeld,
    item.outstanding,
    item.amountPaid,
    item.paymentType,
    item.serviceCode,
    item.fuel,
    item.supplierCode
  ])

  const options = {
    showLabels: true,
    filename,
    headers
  }

  return createCSVFile({ data: csvData, options })
}

const createCSVFromBooking = ({ filename, data, siteUser, isNovasol }, t) => {
  const csvDataForNonSiteUser = [
    { header: t('export:bookingReference'), key: 'bookingId' },
    { header: t('export:bookingType'), key: 'translatedExportBookingState' },
    { header: t('export:accommodationName'), key: 'propertyName' },
    { header: t('export:accommodationCode'), key: 'propertyCode' },
    { header: t('export:customerName'), key: 'leadGuest' },
    { header: t('export:holidayStart'), key: 'startDate' },
    { header: t('duration'), key: 'nights' },
    { header: t('export:holidayEnd'), key: 'endDate' },
    { header: t('created'), key: 'bookedOnDate' },
    { header: t('export:customerEmailAddress'), key: 'email' },
    { header: t('export:customerLandlineNumber'), key: 'telephone' },
    { header: t('export:customerMobileNumber'), key: 'mobile' },
    { header: t('adult'), key: 'adultsCount' },
    { header: t('child'), key: 'childrenCount' },
    { header: t('infant'), key: 'infantsCount' },
    { header: t('export:extras'), key: 'extras' },
    { header: t('export:cancellationDate'), key: 'cancelledDate' },
    { header: t('export:income'), key: 'ownerIncome' },
    { header: t('export:ownerExcessCharge'), key: 'ownerChargeAmount' },
    { header: t('export:pmsBookingRef'), key: 'pmsReference' },
    { header: t('export:specialRequestInformation'), key: 'specialRequirements' },
    { header: t('export:currency'), key: 'currency' },
    { header: t('export:repeatBooker'), key: 'isRepeatLeadGuest' }
  ]

  const csvDataForSiteUser = [
    { header: t('export:bookingReference'), key: 'bookingId' },
    { header: t('export:bookingType'), key: 'translatedExportBookingState' },
    { header: t('export:siteName'), key: 'siteName' },
    { header: t('export:accommodationName'), key: 'propertyName' },
    { header: t('export:accommodationCode'), key: 'propertyCode' },
    { header: t('export:unitCode'), key: 'unitCode' },
    { header: t('export:customerName'), key: 'leadGuest' },
    { header: t('export:holidayStart'), key: 'startDate' },
    { header: t('duration'), key: 'nights' },
    { header: t('export:holidayEnd'), key: 'endDate' },
    { header: t('created'), key: 'bookedOnDate' },
    { header: t('export:customerEmailAddress'), key: 'email' },
    { header: t('export:customerLandlineNumber'), key: 'telephone' },
    { header: t('export:customerMobileNumber'), key: 'mobile' },
    { header: t('adult'), key: 'adultsCount' },
    { header: t('child'), key: 'childrenCount' },
    { header: t('infant'), key: 'infantsCount' },
    { header: t('export:extras'), key: 'extras' },
    { header: t('export:cancellationDate'), key: 'cancelledDate' },
    { header: t('export:accommodationSell'), key: 'holidayPrice' },
    { header: t('export:extrasSellPrice'), key: 'extrasSellPrice' },
    { header: t('export:pmsBookingRef'), key: 'pmsReference' },
    { header: t('export:specialRequestInformation'), key: 'specialRequirements' },
    { header: t('export:currency'), key: 'currency' },
    { header: t('export:repeatBooker'), key: 'isRepeatLeadGuest' }
  ]

  const csvDataForNovasol = [
    { header: t('export:bookingReference'), key: 'bookingId' },
    { header: t('export:bookingType'), key: 'translatedExportBookingState' },
    { header: t('export:accommodationName'), key: 'propertyName' },
    { header: t('export:accommodationCode'), key: 'propertyCode' },
    { header: t('export:unitCode'), key: 'unitCode' },
    { header: t('export:customerName'), key: 'leadGuest' },
    { header: t('export:holidayStart'), key: 'startDate' },
    { header: t('duration'), key: 'nights' },
    { header: t('export:holidayEnd'), key: 'endDate' },
    { header: t('created'), key: 'bookedOnDate' },
    { header: t('export:customerEmailAddress'), key: 'email' },
    { header: t('export:customerLandlineNumber'), key: 'telephone' },
    { header: t('export:customerMobileNumber'), key: 'mobile' },
    { header: t('adult'), key: 'adultsCount' },
    { header: t('child'), key: 'childrenCount' },
    { header: t('infant'), key: 'infantsCount' },
    { header: t('export:extras'), key: 'extras' },
    { header: t('export:cancellationDate'), key: 'cancelledDate' },
    { header: t('export:income'), key: 'ownerIncome' },
    { header: t('export:ownerExcessCharge'), key: 'ownerChargeAmount' },
    { header: t('export:pmsBookingRef'), key: 'pmsReference' },
    { header: t('export:specialRequestInformation'), key: 'specialRequirements' },
    { header: t('export:currency'), key: 'currency' },
    { header: t('export:repeatBooker'), key: 'isRepeatLeadGuest' }
  ]

  const isDateValid = (date) => date && !isNaN(Date.parse(date))

  const getCsvHeaderSet = () => {
    if (siteUser) {
      return csvDataForSiteUser
    }

    if (isNovasol) {
      return csvDataForNovasol
    }

    return csvDataForNonSiteUser
  }

  const csvData = getCsvHeaderSet()
  const headers = csvData.map(({ header }) => header)

  const arrayToString = (text) => {
    let output = text

    if (Array.isArray(text)) {
      if (text.length > 1) {
        output = text.join(' | ')
      } else {
        output = text.join()
      }
    }
    return output
  }

  const displayExtras = (text) => {
    if (Array.isArray(text)) {
      return text
        .filter(({ quantity }) => quantity > 0)
        .map(tx => {
          const translationKey = tx.id ? `extras${tx.id}` : tx.name
          const translatedName = t(translationKey, { defaultValue: tx.name })
          return `${tx.quantity} - ${translatedName}`
        })
        .join(', ')
    }
    return text
  }

  const displayCancelledDate = (text, booking) => {
    if (!booking.hideCancellationDate) {
      return dayjs(text).format(DATE_FORMAT_DISPLAY)
    }

    return ''
  }

  const displayFinancialValue = (value, hideFinancialsForBookingOnExport) => {
    if (!value || hideFinancialsForBookingOnExport) {
      return '0.00'
    }

    return value.toLocaleString(getLocale())
  }

  const configuredData = data.map((b) => {
    const hideFinancialsForBookingOnExport = b.hideBookingFinancialDetail
    const booking = []

    csvData.forEach(({ key }) => {
      let text = b[key] !== undefined ? b[key] : ''
      if (key === 'bookingId' && isNaN(b['bookingId'])) {
        text = ''
      }

      if ((key === 'startDate' ||
        key === 'endDate' ||
        key === 'bookedOnDate') && isDateValid(b[key])
      ) {
        text = dayjs(text).format(DATE_FORMAT_DISPLAY)
      }
      if (key === 'specialRequirements') {
        text = arrayToString(text).replace(/,/g, '')
      }
      if (key === 'extras') text = displayExtras(text)
      if (key === 'email' ||
        key === 'telephone' ||
        key === 'mobile'
      ) text = (text === null ? '' : text)

      if (key === 'ownerIncome' ||
        key === 'ownerChargeAmount' ||
        key === 'holidayPrice' ||
        key === 'extrasSellPrice') {
        text = displayFinancialValue(text, hideFinancialsForBookingOnExport)
      }

      if (key === 'cancelledDate') {
        text = displayCancelledDate(text, b)
      }

      if (key === 'pmsReference') {
        text = (text === null ? '' : text)
      }
      if (key === 'isRepeatLeadGuest') {
        text = text ? 'Y' : ''
      }

      booking.push(text)
    })

    return booking
  })

  const options = {
    showLabels: true,
    filename,
    headers
  }

  return createCSVFile({ data: configuredData, options })
}

export { createCSVFromBooking, createCSVFile, createCsvFromReport }
