import React from 'react'
import type { SelectProps } from '@awaze/design-system.components.select'
import { Label, StyledSelect } from './SelectDropdownDS.styles'

export interface SelectDropdownDSProps extends SelectProps {
  label?: string | false;
  isLoading?: boolean;
  loadingText?: string;
  hideArrow?: boolean;
}

export function SelectDropdownDS ({
  label,
  isLoading,
  loadingText,
  placeholder,
  options,
  hideArrow = false,
  ...props
}: Readonly<SelectDropdownDSProps>) {
  return (
    <>
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <StyledSelect
        {...props}
        options={options}
        placeholder={isLoading ? loadingText : placeholder}
        hideArrow={hideArrow}
      />
    </>
  )
}
