import React from 'react'
import styled from 'styled-components'
import { SelectDropdown } from '@/components/__Inputs'
import { Flex as FlexBox } from 'reflexbox/styled-components'

const TickIcon = styled.span`
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  margin-left: 0.5rem;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  vertical-align: text-bottom;

  &::before, &::after {
    height: 1px;
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &::before {
    width: 5px;
    left: 3px;
    top: 10px;
    transform: rotate(45deg);
  }

  &::after {
    width: 10px;
    left: 6px;
    top: 9px;
    transform: rotate(-45deg);
  }
`

const MessageWrap = styled.div`
    margin-top: 2rem;
    text-align: center;

    h1 {
        margin: 1rem 0;
        font-size: 1.2rem;
    }
`

const Flex = styled(FlexBox)`
    @media only screen and (max-width: 500px) {
        display: block;
    }
`

const ListHeader = styled.div`
    ${({ theme }) => `
        display: flex;
        margin-top: 2rem;
        align-items: center;
        justify-content: space-between;

        h1 {
            flex: 1;
            font-size: 1.5rem;
        }

        .list-header-inner {
            flex: 2;
            display: flex;

            & > * { 
                margin: 0 .4rem; 
            }

            .list-control {
                height: 3rem;
                display: inline-block;
                
            }
        }

        ${theme.mediaQueries.phoneOnly} {
            align-items: baseline;
            flex-direction: column;

            .list-header-inner {
                width: 100%;
                display: block;
                margin-top: 1rem;
                text-align: right;

                .list-control {
                    margin-bottom: 1rem;
                }

                button {
                    width: 8rem;
                    margin-top: .5rem;
                }
            }
        }
    `}
`

const StyledSelect = styled(SelectDropdown)`
    .react_select__single-value {
      width: 90%;
      text-align: center;
    }
`

const Box = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  border-radius: .2rem;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
`

const FloorPrice = styled(({ value, ...rest }) => {
  if (!value) return null

  return (
    <Box {...rest}>
      <div>
        <h3>Floor price</h3>
        <p>This is the lowest price your property would be sold at.</p>
      </div>

      <span>£{value}</span>
    </Box>
  )
})`
    ${({ theme }) => `
      margin: 1rem 0;
      max-width: 30rem;
      margin-left: auto;
  
      div {
        flex: 3;
      }
  
      span {
        flex: 1;
      }
  
      span {
        text-align: right;
      }
  
      h3, span {
        font-size: 1rem;
        font-weight: 600;
      }
  
      ${theme.mediaQueries.phoneOnly} {
        padding: .5rem;
        
        p {
          font-size: .8rem;
        }
      }
    `}
`

export { Flex, MessageWrap, ListHeader, StyledSelect, Box, FloorPrice, TickIcon }
