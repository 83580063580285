import qs from 'qs'
import React from 'react'
import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

const Link = styled(({ to, queryParams, ...props }) => (
  <RouterLink
    to={{
      pathname: to,
      search: queryParams ? `?${qs.stringify(queryParams)}` : ''
    }}
    {...props}
  />
)
)`
  ${({ disabled, primary, theme, small }) => `
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.colors.blackTwo};

    &:hover {
      text-decoration: none;
    }

    &:active {
      font-weight: 600;
    }

    ${disabled && css`
      pointer-events: none;
      color: ${theme.colors.disabled};
    `}

    ${small && css`
      font-size: .75em;
      line-height: 1.2;
    `}

    ${primary && css`
      color: ${theme.colors.secondary}
    `}
  `}
`

Link.displayName = 'Link'

export default Link
