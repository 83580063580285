import React from 'react'
import { OwnerFooter } from '@awaze/design-system.components.owner-footer'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useQuckNavigationLinks from './quickNavLinks/useQuickNavigationLinks'
import CompanyInformation from './companyInfo/CompanyInfo'
import {FooterWrapper} from './Footer.styles'

const Footer = () => {
  const { t } = useTranslation()
  const links = useQuckNavigationLinks()
  const companyInformation = CompanyInformation()
  const quickNavigationTitle = <span data-testid="quick-nav-title">{t('quickNavigation')}</span>
  const privacyLink = { name: t('privacyNotice'), url: 'https://www.awaze.com/privacy-notice/' }

  return (
    <FooterWrapper>
      <OwnerFooter quickNavigationTitle={quickNavigationTitle} privacyLink={privacyLink} quickNavigationLinks={links} companyInformation={companyInformation} LinkComponent={NavLink}/>
    </FooterWrapper>
  )
}

export default Footer
