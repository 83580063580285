import styled from 'styled-components'
import { getConsolidatedBookingColor, getBookingColor } from '@/helpers/bookings'
import IconClosed from '@/assets/icons/IconClosed.svg'
import { Heading } from '@awaze/design-system.components.text'

export const BookingType = styled.div<{
  bookingType?: string;
  bookingState?: string;
}>`
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: .5rem;
    background: ${({bookingType, bookingState}) => bookingState ? getConsolidatedBookingColor(bookingState) : getBookingColor(bookingType)};
    position: relative;
`
export const BookingTypeIcon = styled.div<{
  bookingType?: string;
  bookingState?: string;
}>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: .5rem;
  position: relative;
  background-image: ${({ bookingType }) => bookingType === 'Closed' ? `url(${IconClosed})` : 'none'};
  background-size: cover;
  background-color: ${({ bookingType, bookingState }) => bookingType !== 'Closed' && (bookingState ? getConsolidatedBookingColor(bookingState) : getBookingColor(bookingType))};
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const ModalContentWrapper = styled.div`
  margin-bottom: 32px;
`
export const StyledHeader = styled(Heading)`
  font-size: 1.5rem;

  @media (max-width: 600px) {
    font-size: 1.25rem; 
  }
`
