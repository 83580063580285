import React from 'react'
import { SelectDropdown } from '@/components/__Inputs'
import { useBookingChartContext } from '@/components/__BookingChartComponents/context'
import PropTypes from 'prop-types'
import DatePicker from '@/components/__Inputs/DatePicker'
import { useBookingChartDatesContext } from '../context/BookingChartDatesProvider'
import { useBookingChartBookingsContext } from '../context/Bookings/BookingChartBookingsProvider'
import { HeaderTop, PropertyDropdownWrapper } from './PageHeader.styles'

const UnitChartHeader = () => {
  const {
    isLoadingChart,
    allSitesWithProperties,
    filteredSite,
    setFilteredSite
  } = useBookingChartContext()

  const {
    highlightedDate,
    todaysDate,
    setStartDate
  } = useBookingChartDatesContext()

  const { setBookingsByUnit } = useBookingChartBookingsContext()

  const hasOnlyOneSite =
    allSitesWithProperties &&
    allSitesWithProperties.sites &&
    allSitesWithProperties.sites.length === 1

  const showTwoYears = () => {
    const NOVEMBER_MONTH = 10
    const DECEMBER_MONTH = 11

    if ([NOVEMBER_MONTH, DECEMBER_MONTH].includes(currentYearMonth)) {
      return new Date((todaysDate.getFullYear() + 2), 11, 31)
    }
    return new Date((todaysDate.getFullYear() + 1), 11, 31)
  }

  const currentYearMonth = todaysDate.getMonth()

  const backToToday = (e) => {
    e.preventDefault()

    if (todaysDate.getTime() !== highlightedDate.getTime()) {
      setStartDate(todaysDate)
      setBookingsByUnit(null)
    }
  }

  return (
    <>
      {!isLoadingChart && allSitesWithProperties &&
      (
        <HeaderTop id="mobileHeader">
          <div className='dropDownContainer'>
            {allSitesWithProperties && allSitesWithProperties.sites && (
              <PropertyDropdownWrapper disabled={hasOnlyOneSite}>
                <div className='dropdownWrapper'>
                  <SelectDropdown
                    disabled={hasOnlyOneSite}
                    value={filteredSite}
                    options={allSitesWithProperties.sites}
                    onChange={(site) => { setFilteredSite(site); setBookingsByUnit(null) }}
                    loadingText="Loading sites..."
                    getOptionLabel={(option) => option['siteName']}
                    getOptionValue={(option) => option['siteId']}
                  />
                </div>
              </PropertyDropdownWrapper>
            )}
          </div>
          <div className='datePickerContainer' data-testid='datePickerCalendar'>
            <div className='datePickerCalendar'>
              <DatePicker
                name="startPicker"
                calendarClassName="startCalendar"
                date={highlightedDate}
                minDate={new Date((todaysDate.getFullYear() - 1), 0, 1)}
                maxDate={showTwoYears()}
                onChange={(date) => { setStartDate(date); setBookingsByUnit(null) }}
                showYearDropdown
                closeOnScroll
                onTap
              />

            </div>
            <div className='today' data-testid='todayChartBtn' onClick={backToToday}>Today</div>
          </div>
        </HeaderTop>)
      }
    </>
  )
}

UnitChartHeader.propTypes = {
  allSitesWithProperties: PropTypes.object,
  filteredSite: PropTypes.object,
  setFilteredSite: PropTypes.func,
  hasOnlyOneSite: PropTypes.bool
}

export default UnitChartHeader
