import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { createCookie } from '@/helpers/cookies'
import useUserBrand from '@/hooks/useUserBrand'

const USER_MARKET_COOKIE_NAME = 'Drupal.visitor.awaze_user_market'
const USER_MARKET_UNKNOWN_MARKET = 'Unknown'
const USER_LANGUAGE_COOKIE_NAME = 'Drupal.visitor.awaze_user_language'
const USER_LANGUAGE_DEFAULT_VALUE = 'en'

const setCorrectMarketValue = (market: string) => {
  if (market) { return market }

  return USER_MARKET_UNKNOWN_MARKET
}

export function useOwnerServicesCookies () {
  const [cookiesSet, setCookiesSet] = useState(false)
  const { isAwaze, userProductMarkets, loading } = useUserBrand()

  useEffect(() => {
    if (!loading) {
      if (isAwaze) {
        const marketForUser = (userProductMarkets && userProductMarkets.length > 0) ? setCorrectMarketValue(userProductMarkets[0]) : USER_MARKET_UNKNOWN_MARKET
        const cookieExpiration = dayjs().add(1, 'day').date()
        createCookie(USER_MARKET_COOKIE_NAME, marketForUser, cookieExpiration, '/')
        createCookie(USER_LANGUAGE_COOKIE_NAME, USER_LANGUAGE_DEFAULT_VALUE, cookieExpiration, '/')
        setCookiesSet(true)
      }
    }
  }, [isAwaze, userProductMarkets, loading])

  return { cookiesSet }
}
