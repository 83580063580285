import styled, { createGlobalStyle } from 'styled-components'

export const ReviewResponseGlobalStyles = createGlobalStyle`
  body {
    background: ${({theme}) => theme.colors.white};
  }
`

export const PageWrapper = styled.div`
  width: 792px; 
  max-width: 792px;
  box-sizing: border-box;
  min-height: 60vh;
  padding: 2rem 0 2rem;


${({ theme }) => theme.mediaQueries.phoneOnly} {
  width: calc(100% - 2rem);
}
 
@media (min-width: 600px) and (max-width: 1024px) {
  width: calc(100% - 4rem);
}
`
