import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Page, Document, StyleSheet } from '@react-pdf/renderer'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import Theme from '../../../../global/Theme'
import { useTranslation } from 'react-i18next'
import {handleDiacriticChars} from '../../../handleDiacriticChars/diacriticChars'

const styles = StyleSheet.create({
  page: {
    padding: 20
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: 'bolder',
    textAlign: 'center',
    backgroundColor: Theme.colors.rowBorder,
    paddingTop: 5,
    paddingBottom: 5
  },
  tableCell: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: 'center'
  }
})

const HeadCell = (props) => <TableCell style={styles.tableHeader} {...props} />

const Cell = (props) => <DataTableCell style={styles.tableCell} {...props} />

const BookingsPDF = ({ bookings, title, siteUser, isNovasol }) => {
  const { t } = useTranslation()

  const getContacts = (r) => {
    const items = []
    if (r.email) items.push(r.email)
    if (r.mobile) items.push(r.mobile)
    if (r.telephone) items.push(r.telephone)

    return items.length ? `${items.join(`\n`)}` : ''
  }

  return (
    <Document title={title}>
      <Page size="A4" style={styles.page} orientation="landscape">
        <Table data={bookings}>
          <TableHeader>
            {siteUser && <HeadCell>{t('Site')}</HeadCell>}
            <HeadCell>{t('accommodation')}</HeadCell>
            {(siteUser || isNovasol) && <HeadCell>{t('unit')}</HeadCell>}
            <HeadCell>{(t('bookingType'))}</HeadCell>
            <HeadCell>{t('arrival')}</HeadCell>
            <HeadCell>{t('departure')}</HeadCell>
            <HeadCell weighting={0.5}>{t('nights')}</HeadCell>
            <HeadCell>{t('bookingRef')}</HeadCell>
            <HeadCell>{handleDiacriticChars(t('export:pdf:customerName'))}</HeadCell>
            <HeadCell>{t('export:pdf:contactDetails')}</HeadCell>
          </TableHeader>
          <TableBody>
            {siteUser && <Cell getContent={r => r.siteName}/>}
            <Cell getContent={r => r.propertyName}/>
            {(siteUser || isNovasol) && <Cell getContent={r => r.unitCode}/>}
            <Cell getContent={r => `${handleDiacriticChars(r.translatedUserFriendlyBookingState)}`}/>

            <Cell getContent={r => dayjs(r.startDate).format('DD/MM/YY')}/>
            <Cell getContent={r => dayjs(r.endDate).format('DD/MM/YY')}/>
            <Cell weighting={0.5} getContent={r => r.nights}/>
            <Cell getContent={r => !isNaN(r.bookingId) ? r.bookingId : ''}/>
            <Cell getContent={r => r.leadGuest}/>
            <Cell getContent={getContacts}/>
          </TableBody>
        </Table>
      </Page>
    </Document>
  )
}

BookingsPDF.propTypes = {
  bookings: PropTypes.array,
  title: PropTypes.string,
  siteUser: PropTypes.bool,
  isNovasol: PropTypes.bool
}

export default BookingsPDF
