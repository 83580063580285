import styled from 'styled-components'
import { Select } from '@awaze/design-system.components.select'

export const Label = styled.label`
  display: inline-block;
  padding-bottom: .5rem;
`
export const StyledSelect = styled(Select)<{ hideArrow: boolean }>`
  ${({ hideArrow }) => hideArrow && `
    background-image: none;
    pointer-events: none;
  `}
`
