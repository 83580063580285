import styled from 'styled-components'
import BookingDetails from '@/components/__BookingComponents/BookingDetails'
import { ItemsContainer } from '@/components/__BookingComponents/BookingDetails/BookingDetails.styles'
import { Button } from '@awaze/design-system.components.button'

export const BookingDetailsStyled = styled(BookingDetails)`
  ${ItemsContainer} {
    margin-bottom: 1.5rem;
  }

  @media (min-width: 769px) {
    ${ItemsContainer} {
      --item-column-size: 12rem;
    }
  }
`
export const StyledText = styled(Text)`
     margin-left: 1.75rem
     `

export const ButtonContent = styled.div`
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        margin-left: 1rem;
        margin-right: 0.75rem;
    }
    p{
        margin-right: 1rem;
    }
`
export const StyledMarginLeft = styled.div`
   margin-left: 2rem;
`

export const CancelButton = styled(Button)`
${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 100%;
}
`
