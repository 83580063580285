import React from 'react'
import PropTypes from 'prop-types'
import { BookingSection, bookingSectionHasData } from './sections/BookingSection'
import { GuestSection, guestSectionHasData } from './sections/GuestSection'
import { PriceSection, priceSectionHasData } from './sections/PriceSection'
import { ProvisionalBookingBanner, ProvisionalBookingHeadline, ProvisionalBookingText } from './BookingDetails.styles'
import { InfoCircle } from '@/components/__SVG'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const BookingDetails = ({ booking, showProvisionalBanner = false, ...rest }) => {
  const { t } = useTranslation()

  if (!booking) {
    return null
  }

  const {
    startDate,
    endDate,
    arrivalDatetime,
    hideArrivalDate,
    id,
    reservedOnDate,
    extras,
    bookingCurrency: currency,
    holidayPrice,
    party,
    specialRequirements,
    propertyName,
    propertyCode,
    unitCode,
    ownerIncome,
    ownerChargeAmount,
    showOwnerChargeAmount,
    duration
  } = booking

  const provisionalExpirationDate = dayjs(booking && booking.provisionalExpirationDate).format('D MMMM YYYY')
  const hideProvisionalExpirationDate = booking?.hideProvisionalExpirationDate === undefined ? true : booking?.hideProvisionalExpirationDate

  return (
    <div {...rest}>
      {!hideProvisionalExpirationDate && showProvisionalBanner &&
      <ProvisionalBookingBanner data-testid='provisional-banner'>
        <InfoCircle />
        <div>
          <ProvisionalBookingHeadline>{t('provisionalExpiration', { date: provisionalExpirationDate })}</ProvisionalBookingHeadline>
          <ProvisionalBookingText>{t('unpaidProvisionalWillBeRemoved')}</ProvisionalBookingText>
        </div>
      </ProvisionalBookingBanner>}
      <BookingSection
        startDate={startDate}
        endDate={endDate}
        expectedArrival={arrivalDatetime}
        propertyName={propertyName}
        propertyCode={propertyCode}
        unitCode={unitCode}
        id={id}
        reservedOnDate={reservedOnDate}
        ownerIncome={ownerIncome}
        ownerChargeAmount={ownerChargeAmount}
        hideArrivalDate={hideArrivalDate}
        showOwnerChargeAmount={showOwnerChargeAmount}
        showBottomBorder={(bookingSectionHasData(booking) && guestSectionHasData(booking)) || (bookingSectionHasData(booking) && priceSectionHasData(booking))}
        currency={currency}
        duration={duration}
      />
      <GuestSection party={party} showBottomBorder={guestSectionHasData(booking) && priceSectionHasData(booking)}/>
      <PriceSection specialRequirements={specialRequirements} extras={extras} currency={currency} holidayPrice={holidayPrice} />
    </div>
  )
}

BookingDetails.propTypes = {
  booking: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    arrivalDatetime: PropTypes.string,
    hideArrivalDate: PropTypes.bool,
    provisionalExpirationDate: PropTypes.string,
    id: PropTypes.string,
    reservedOnDate: PropTypes.string,
    hideProvisionalExpirationDate: PropTypes.bool,
    extras: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      itemQty: PropTypes.number,
      quantity: PropTypes.number,
      cost: PropTypes.number.isRequired,
      hideQuantity: PropTypes.bool
    })),
    bookingCurrency: PropTypes.string,
    holidayPrice: PropTypes.number,
    party: PropTypes.shape({
      adultsCount: PropTypes.number,
      childrenCount: PropTypes.number,
      infantsCount: PropTypes.number,
      petsCount: PropTypes.number,
      partyLead: PropTypes.shape({
        customerId: PropTypes.string,
        longName: PropTypes.string,
        nationality: PropTypes.string,
        contacts: PropTypes.shape({
          phone: PropTypes.string,
          email: PropTypes.string,
          cellPhone: PropTypes.string,
          landLine: PropTypes.string,
          defaultContactDisplay: PropTypes.string
        }),
        postCode: PropTypes.string,
        town: PropTypes.string
      })
    }),
    specialRequirements: PropTypes.arrayOf(PropTypes.string),
    propertyName: PropTypes.string,
    propertyCode: PropTypes.string,
    unitCode: PropTypes.string,
    ownerIncome: PropTypes.number,
    ownerChargeAmount: PropTypes.number,
    showOwnerChargeAmount: PropTypes.bool,
    duration: PropTypes.number
  }),
  showProvisionalBanner: PropTypes.bool,
  style: PropTypes.object
}

export default BookingDetails
