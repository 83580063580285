import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { useUser } from '@/context/UserProvider'
import { PageContainer, ErrorMessage } from './UnitChartPage.styles'
import { PageWrapper } from '@/components/__UI'
import ColorLegend from '../../components/__BookingChartComponents/colorLegend/ColorLegend'
import ChartTable from '@/components/__BookingChartComponents/unit_chart_table/table/Table'
import PropertyList from '../../components/__BookingChartComponents/propertyList/PropertyList'
import PageHeader from '@/components/__BookingChartComponents/pageHeader/PageHeader'
import {
  BookingChartProvider,
  BookingChartDatesProvider
} from '@/components/__BookingChartComponents/context'
import { BookingChartBookingsProvider } from '@/components/__BookingChartComponents/context/Bookings'

const UnitChartPage = () => {
  const { hasPermission } = useUser()
  const hasPermissionToView = hasPermission('Chart')

  const GlobalStyle = createGlobalStyle`
  body{
    background-color: ${({theme}) => theme.colors.white}
  }
  `

  if (!hasPermissionToView) {
    return (
      <PageWrapper title="Unit Chart">
        <ErrorMessage>
          You do not have permissions to view this page. If you have the wrong
          permissions, please contact the administration{' '}
        </ErrorMessage>
      </PageWrapper>
    )
  } else {
    return (
      <BookingChartProvider>
        <BookingChartDatesProvider>
          <BookingChartBookingsProvider>
            <GlobalStyle />
            <PageContainer>
              <div className='selectContainer'>
                <PageHeader />
              </div>
              <div className='mainTableUnitContainer'>
                <div className='unitsAndSites'>
                  <PropertyList />
                  <div className='colorLegendPageContainer'>
                    <ColorLegend />
                  </div>
                </div>
                <div className='scrollTableContainer'>
                  <ChartTable />

                </div>
              </div>
            </PageContainer>
          </BookingChartBookingsProvider>
        </BookingChartDatesProvider>
      </BookingChartProvider>
    )
  }
}

export default UnitChartPage
