import DesignTokens from '@/global/DesignTokens'
import styled from 'styled-components'

const HeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1rem;
  min-width:  95.5vw;

  @media (min-width: 600px) and (max-width: 1199px) {
    margin-right: 1rem;
  }

  .datePickerContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    
    .datePickerCalendar{
      margin-right: 1rem;
      img{
          position: absolute;
          margin: 0;
          right: 1rem;
          width: 1.5rem;
          margin-top: 4.5%;
          z-index: 1;
        }

      input{
        height: 3rem;
        max-width: 100%;
      }
    }

    .today{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8vw;
      height: 3rem;
      color: ${({ theme }) => theme.colors.txtBodyOnWhite};
      border: 1px solid ${DesignTokens.ColorGrey400};
      border-radius: ${({ theme }) => theme.borderRadius};  
      cursor: pointer;
      @media (min-width: 600px) and (max-width: 1199px) {
        width: 12vw;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-top: 1.5rem;

    .dropDownContainer{
      height: 3rem;
      margin-bottom: 1rem;
    }

    .datePickerContainer{
    width: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    cursor:'pointer';
    margin-bottom: 1rem;


      .datePickerCalendar{
        width: 60vw;
        margin-right: 1rem;
        img{
            position: absolute;
            margin: 0;
            right: 1rem;
            width: 1.5rem;
            margin-top: 4.5%;
            z-index: 1;
          }

        input{
          height: 3rem;
          max-width: 100%;
        }
      }

      .today{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30vw;
        height: 3rem;
        color: ${({ theme }) => theme.colors.txtBodyOnWhite};
        border: 1px solid ${DesignTokens.ColorGrey400};
        border-radius: ${({ theme }) => theme.borderRadius};  
        cursor:'pointer';
      }
    }
  } 
`

const PropertyDropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 18vw;
  max-width: 20vw;
  margin-bottom: 16px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  .dropdownWrapper{
      width: 96vw;
      max-width: 96vw;
      margin-right: 1rem;
    }

    /* iPAD */
  @media (min-width: 600px) and (max-width: 1199px) {
    width: 31vw;
    max-width: 32vw;
  }

  /* MOBILE */
    ${({ theme }) => theme.mediaQueries.phoneOnly} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96vw;
    max-width: 96vw;

    .dropdownWrapper{
      width: 96%;
      max-width: 96vw;
      margin-right: 1rem;
    }
  }
`

export { HeaderTop, PropertyDropdownWrapper }
