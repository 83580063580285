import styled from 'styled-components'

export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 50%;

    ${({theme}) => theme.mediaQueries.iPadAir} {
        max-width: 100%;
  }
`

export const ErrorText = styled.p`
  color: ${({theme}) => theme.colors.error};
`
