import { getConsolidatedBookingColor } from '@/helpers/bookings'
import styled from 'styled-components'
import Button from '@/components/__Inputs/Button'
import DesignTokens from '@/global/DesignTokens'

const responsivePaddingRightLeft = `
  padding-right: 0.875rem;
  padding-left: 0.875rem;

  @media (max-width: 1260px) {
    padding-right: 0.6rem;
    padding-left: 0.6rem;
  }

`

export const StyledTable = styled.table`
  flex: 1;
  background-color: white;
  border-spacing: 0;
  outline: ${({ theme }) => theme.ColorGrey300} 1px solid;
  isolation: isolate;
  contain: paint;
  border-radius: 4px;
`

export const StyledThead = styled.thead`
  background-color: ${({ theme }) => theme.ColorGrey25};
  height: 3rem;
  position: sticky;
  top: 0;
  z-index: 1;
`

export const TableHeader = styled.th`
  vertical-align: middle;
  text-align: start;
  ${responsivePaddingRightLeft}
`

export const HeaderContainer = styled.div`
  width: ${props => (props.useFitContent ? 'auto' : 'fit-content')};
  position: relative;
  cursor: ${props => (props.canSort ? 'pointer' : 'default')};
`

export const HeaderHoverElement = styled.div`
  height: calc(100% + 1rem);
  width: calc(100% + 1.5rem);
  position: absolute;
  margin-top: -0.5rem;
  margin-left: -1rem;

  &:hover {
    background: ${({ theme }) => theme.ColorGrey100};
  }
`

export const HeaderFlexbox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  position: relative;
  white-space: nowrap;
  pointer-events: none;
  ${({ theme }) => theme.fonts.Title200};
`

export const StyledTableContainer = styled.div`
  display: flex;
`

export const StyledTd = styled.td`
  padding: 0.625rem;
  ${responsivePaddingRightLeft}
  vertical-align: middle;
`

export const StyledTr = styled.tr`
  border-bottom: 1px ${({ theme }) => theme.ColorGrey100} solid;
  min-height: 25rem;
`

export const StyledHeaderRow = styled.tr`
  border-bottom: 1px ${({ theme }) => theme.ColorGrey100} solid;
`

export const StyledRow = styled.tr`
  height: 4.5625rem;
  
  &:not(:last-child) {
    border-bottom: 1px ${({ theme }) => theme.ColorGrey100} solid;
  }

  &:hover {
    background-color: ${({ theme }) => theme.ColorGrey25};
  }
`

export const StyledDivider = styled.div`
  height: 0.0625rem;
  background-color: blue;
`

export const ConsolidatedTypeColorIndicator = styled.p`
  background: ${({bookingState}) => getConsolidatedBookingColor(bookingState)};
  width: 0.35rem;
  height: 3rem;
  border-radius: 0.625rem;
`

export const RowsContainer = styled.tbody``

export const NameCell = styled.div`
  max-width: 11.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CountryCell = styled.div`
  ${({ theme }) => theme.fonts.Text200};
`

export const AccommodationCell = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to display */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`

export const ChevronContainer = styled.div.attrs({
  tabIndex: '0'
})`
  cursor: pointer;
  width: fit-content;
`

export const StyledBookingDetails = styled.div`
    font-size: 1.3vw;
    max-width: 80rem;


    h4 {
        font-size: 1rem;
        max-width: 20rem;
    }

    table {
        table-layout: fixed;

        td:nth-child(1) {
            width: 4rem;
            margin-right: 1rem;
            word-wrap: break-word;
            font-weight: 600;

            ${({ theme }) => theme.mediaQueries.phoneOnly} {
            width: 8rem;
            }
        }

        td:nth-child(2) {
            padding-left: 0;
            width: 10rem;
            font-weight: normal;
        }
    }

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
        width: 100%;
        table {
            td:nth-child(1) {
                width: 7rem;
            }
        }
    }

    @media only screen and (max-width: 340px) {
        table {
            td:nth-child(1) {
                width: 5rem;
            }

            td:nth-child(2) {
                padding-left: 0.5rem;
            }
        }
    }
`
export const CancelButton = styled(Button)`
    width: auto;
    height: auto;
    margin: 1rem auto;
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
    background-color: transparent;

    &:hover{
        color: ${DesignTokens.ColorButtonSolidSecondaryBackgroundHover};
        border: 2px solid ${DesignTokens.ColorButtonSolidSecondaryBackgroundHover};
        background: #f9f9f9;
        transition: all 0.4s ease-in-out;
    }


    @media screen and (max-width: 1024px) {
        margin: 0 0 1.5rem 3.5rem;
    }

    ${({theme}) => theme.mediaQueries.phoneOnly} {
        margin: 0 0 1.5rem 3.5rem;
        width: 70%;
    }
`
export const ButtonContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        margin-left: 1rem;
        margin-right: 12px;
    }
    p{
        margin-right: 1rem;
    }
`
