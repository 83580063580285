import DesignTokens from '@/global/DesignTokens'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`

.react-datepicker-wrapper {

input {
  border: none !important;
  height: 3rem;
}
}

${({ theme }) => `${theme.mediaQueries.tablet} {
  margin-right: 0;
  z-index: 0;
}`}

${({ theme }) => theme.mediaQueries.mediumLargeScreen} {
    width: 100%;
  }
`

const LabelContainer = styled.div`
margin-top: 0;

${({ theme }) => theme.mediaQueries.tabletPortraitUp} {
  width: 100%;
  }
`

const Inner = styled.div`
    position: relative;
  & > * {
    margin-bottom: 0.5rem;
    z-index: 1;

    @media screen and (max-width: 360px) {
      max-width: 8rem;
    }
  }

  ${({ theme }) => theme.mediaQueries.tabletPortraitUp} {
    display: flex;
    align-items: center;

    & > * {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.mediaQueries.mediumLargeScreen} {
    display: flex;
    width: 100%;
  }

`

const Dash = styled((props) => (<span {...props}>-</span>))`
  display: block;
  margin: 0.2rem 0.2rem;
  font-size: 1.5rem;
  line-height: 1.5;

  ${({ theme }) => theme.mediaQueries.tabletPortraitUp} {
    display: block;
  }
`
const DatePickerInput = styled.button`
  display: block;
  cursor: pointer;
  position: relative;
  padding: 1rem;
  padding-left: 3rem;
  font-size: 1rem;
  margin-bottom: 0 !important;
  color: ${({ theme }) => theme.colors.blackTwo};
  background-color: ${({ theme }) => theme.colors.white};
  height: 3rem;
  border: 1px solid ${DesignTokens.ColorGrey400};
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ theme }) => theme.mediaQueries.mediumLargeScreen} {
    width: 100%;
  }

  @media screen and (max-width: 360px) {
    padding-left: 0;
  }
`

export { Container, LabelContainer, Inner, Dash, DatePickerInput }
