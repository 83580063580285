import styled from 'styled-components'

export const ButtonSection = styled.div`
  display: flex;
  margin-top: 1.5rem;
  gap: 1rem;

  button {
    flex-grow: 1;
    max-width: 10rem;
  }
`

export const PropertyList = styled.ul`
  list-style: disc;
  margin-top: .5rem;
  margin-left: 1.5rem;

  .accepted {
    color: ${({ theme }) => theme.colors.success};
  }
  .rejected {
    color: ${({ theme }) => theme.colors.error};
  }
`

export const ApprovalMessage = styled.div`
  margin-top: 1rem;
`

export const AcceptanceTableAndMessageContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.phoneOnlyDS} {
    /* display message on top of table on mobile */
    flex-direction: column-reverse;
  }
`
