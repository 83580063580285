import styled from 'styled-components'

const ErrorMessage = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding: 3rem;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  box-shadow: ${({ theme }) => theme.boxShadowSoft};
`
const PageContainer = styled.div`
  max-width: 100vw;
  width: fit-content;
  margin-left: 2.4rem;
  margin-top: 1rem;
  margin-right: 2.4rem;

  .selectContainer{
      position: sticky;
      top: 0;
      background: ${({ theme }) => theme.colors.white};
      z-index: 3;
      padding-top: 1rem;
    
  }

  .mainTableUnitContainer{
    overflow: auto;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  
    scrollbar-width: none;

  .unitsAndSites{
      width: 17vw;
      position: sticky;
      left: 0;
      z-index: 2;

      .colorLegendPageContainer{
        margin-top: 2rem; 
        margin-bottom: 1rem;
      }
    }
    .scrollTableContainer{
      width: 100%;
    }
  }

  /* MOBILE */
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: auto;
    margin-left: 1rem !important;
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 1rem;
    margin-right: 0;

    .selectContainer{
      position: sticky;
      top: 4rem;
      background: ${({ theme }) => theme.colors.white};
      z-index: 3;
    }

    .mainTableUnitContainer{
      overflow: auto;
      height: 70vh;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;

      ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; 
    scrollbar-width: none; 

      .unitsAndSites{
        width: 35%;
        position: sticky;
        left: 0;
        z-index: 2;
      }
      .scrollTableContainer{
        width: 65%;
      }
    }
  }

/* iPAD */
  @media (min-width: 600px) and (max-width: 1199px) {
    width: auto;
    margin-left: 1rem !important;
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 1rem;
    margin-right: 0;

    .selectContainer{
      background: ${({ theme }) => theme.colors.white};
      z-index: 3;
      margin-right: 1rem;
      padding-top: 0.5rem;
      width: 100%;
      height: 7rem;
      position: sticky;
      top: 0;
    }

    .mainTableUnitContainer{
      overflow: auto;
      height: 70vh;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;

      ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; 
    scrollbar-width: none; 

      .unitsAndSites{
        width: 30%;
        position: sticky;
        left: 0;
        z-index: 2;

      }
      .scrollTableContainer{
        width: 70%;

      }
    }
  }

`
export { PageContainer, ErrorMessage }
