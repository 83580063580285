import styled from 'styled-components'
import { Text } from '@awaze/design-system.components.text'
import { Button } from '@awaze/design-system.components.button'
import { Box } from '@awaze/design-system.components.box'
import { Link } from 'react-router-dom'
import { Flag } from './sections/Flag'

/* ReviewList Styles */
export const ReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

export const ReviewerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const NoWrap = styled.span`
  display: inline-flex;
  align-items: center;

  white-space: nowrap;
  `

export const TextWithMargin = styled(Text)`
  margin-bottom: 0.75rem;
`

export const TextWithFlex = styled(Text)`
  display: flex;
  align-items: center;
`
export const StyledBox = styled(Box)`
  margin-left: 1rem;
  background-color: ${({ theme }) => theme.colors.tableBorders};
`
/* RespondReview Styles */
export const StyledLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.buttonSolidPrimary};
  display: inline-block;
  width: 91px;
  height: 48px;
  color: white;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
&:hover {
  background-color: #36703F;
}
${({ theme }) => theme.mediaQueries.phoneOnly} {
  width: 100%
}
`

export const ResponseContainer = styled.div`
  display: flex;
  justify-content: flex-end; 
`

/* TranslateReview Styles */
export const StyledLinkBtn = styled(Button)`
  color: ${({ theme }) => theme.colors.link}; 
  font-weight: normal;
  padding: 0;
  margin-bottom: 0.75rem;
  


&:hover, &:active {
  font-weight: normal;
  color: #304354; 
}
`
export const TranslateMargin = styled(Text)`
  margin-top: 0.75rem;
`

export const TextWithMarginRight = styled(Text)`
  margin-right: 0.3125rem;
`

export const TranslatedReviewMargin = styled.div`
  margin-bottom: 0.75rem;
`

export const StyledFlag = styled(Flag)`
  width: 20px;
  height: 20px;
`
