import React from 'react'
import { BigScreenContainer, BookingListFilterMenuItemLaptopAndUp, FilterContainer } from './BookingListFilterMenu.styles'
import { useBookingListContext } from '@/context/BookingListProvider'
import { SelectDropdown, StyledLabel as StyledLabelBigscreen } from '@/components/__Inputs'
import { useTranslation } from 'react-i18next'
import useWindowSize from '@/hooks/useWindowSize'

const BookingListFilterMenu = () => {
  const { t } = useTranslation()
  const { width } = useWindowSize()

  const menuBtn = [
    { name: t('arrivalDate'), value: 'arrival', testid: 'arrivalDateFilter' },
    { name: t('dateCreated'), value: 'created', testid: 'createdDateFilter' },
    { name: t('departureDate'), value: 'departure', testid: 'departureDateFilter' },
    { name: t('active'), value: 'active', testid: 'activeBookingsFilter' }
  ]

  const isMobile = width <= 1024

  const {
    searchBy,
    setSearchBy,
    setSortedBy
  } = useBookingListContext()

  const addingActiveStyles = (value) => {
    if (value === searchBy) {
      // Do nothing if the same option is selected again
      return
    }

    setSearchBy(value)

    if (value === 'arrival' || value === 'active') {
      setSortedBy({ name: 'arrival', order: 'ASC' })
    }
    if (value === 'created') {
      setSortedBy({ name: 'bookingDate', order: 'ASC' })
    }
    if (value === 'departure') {
      setSortedBy({ name: 'departureDate', order: 'ASC' })
    }
  }

  const determineFilterClassName = (value) => {
    if ((searchBy === '' && value === menuBtn[0].value) || value === searchBy) {
      return 'active'
    }

    return ''
  }

  const selectedOption = menuBtn.find((option) => option.value === searchBy)

  return (
    <>
      {isMobile ? (
        <FilterContainer data-testid='mobileDropdown'>
          <SelectDropdown
            label={t('quickFilters')}
            className="react_select__wrapper"
            name="selectList"
            id="selectList"
            value={searchBy}
            options={[
              { value: 'arrival', label: t('arrivalDate') },
              ...menuBtn
                .filter((option) => option.value !== 'arrival')
                .map((option) => ({
                  value: option.value,
                  label: option.name
                }))
            ]}
            placeholder={selectedOption ? selectedOption.name : 'Select filter'}
            onChange={(selectedOption) => addingActiveStyles(selectedOption.value)}
          />
        </FilterContainer>
      ) : (
        <BigScreenContainer>
          <StyledLabelBigscreen>
            {t('quickFilters')}
          </StyledLabelBigscreen>
          <BookingListFilterMenuItemLaptopAndUp id="navigation" >
            {menuBtn.map((option) => (
              <button
                data-testid={option.testid}
                className={determineFilterClassName(option.value)}
                key={option.name}
                onClick={() => addingActiveStyles(option.value)}
                aria-label="FilterMenuItems"
              >
                {option.name}
              </button>
            ))}
          </BookingListFilterMenuItemLaptopAndUp>
        </BigScreenContainer>
      )}
    </>
  )
}

export default BookingListFilterMenu
